/*============================================================================
  Shopify Timber v1.4.0
  Copyright 2014 Shopify Inc.
  Author Carson Shold @cshold
  Built with Sass - http://sass-lang.com/

  Some things to know about this file:
    - Sass is compiled on Shopify's server so you don't need to convert it to CSS yourself
    - The output CSS is compressed and comments are removed
    - You cannot use @imports in this file
        * Use grunt or gulp tasks to enable @imports - https://github.com/Shopify/shopify-css-import
    - Helpers variables, mixins, and starter classes are provided. Change as needed.
==============================================================================*/
/*============================================================================
  Table of Contents

  #Breakpoint and Grid Variables
  #General Variables
  #Sass Mixins
  #Normalize
  #Grid Setup
  #Basic Styles
  #Helper Classes
  #Typography
  #Rich Text Editor
  #Links and Buttons
  #Lists
  #Tables
  #OOCSS Media Object
  #Images and Iframes
  #Forms
  #Icons
  #Pagination
  #Site Header
  #Site Nav and Dropdowns
  #Mobile Nav Bar
  #Site Footer
  #Product Grid and List Views
  #Collection Filters
  #Breadcrumbs
  #Product Page
  #Blogs and Comments
  #Notes and Form Feedback
  #Cart Page
  #Demo Styles - for empty store state
  #FlexSlider
  #Magnific Popup
  #Product Reviews
  #Password Page
  #Custom Content
  #Blank States
  #Lazysizes
  #Featured blog post
==============================================================================*/
/*============================================================================
  #Breakpoint and Grid Variables
==============================================================================*/
/*================ The following are dependencies of csswizardry grid ================*/
/*============================================================================
  #General Variables
==============================================================================*/
/*================ Typography ================*/
/*============================================================================
  Get font-families from theme settings
==============================================================================*/
@font-face {
  font-family: "icons";
  src: url("//cdn.shopify.com/s/files/1/2383/2435/t/4/assets/icons.eot?v=4915883424828942323");
  src: url("//cdn.shopify.com/s/files/1/2383/2435/t/4/assets/icons.eot?v=4915883424828942323#iefix") format("embedded-opentype"), url("//cdn.shopify.com/s/files/1/2383/2435/t/4/assets/icons.ttf?v=7649664869248826296") format("truetype"), url("//cdn.shopify.com/s/files/1/2383/2435/t/4/assets/icons.svg?v=8602671668554796063#timber-icons") format("svg");
  font-weight: normal;
  font-style: normal;
  sfont-display: swap; }

/*============================================================================
  #Sass Mixins
==============================================================================*/
.clearfix:after {
  content: "";
  display: table;
  clear: both; }

/*============================================================================
  Prefix mixin for generating vendor prefixes.
  Based on https://github.com/thoughtbot/bourbon/blob/master/app/assets/stylesheets/addons/_prefixer.scss
  Usage:
    // Input:
    .element {
      @include prefix(transform, scale(1), ms webkit spec);
    }
    // Output:
    .element {
      -ms-transform: scale(1);
      -webkit-transform: scale(1);
      transform: scale(1);
    }
==============================================================================*/
/*============================================================================
  Flexbox prefix mixins from Bourbon
    https://github.com/thoughtbot/bourbon/blob/master/app/assets/stylesheets/css3/_flex-box.scss
==============================================================================*/
/*============================================================================
  #Normalize
==============================================================================*/
*, input, :before, :after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
  display: block; }

audio, canvas, progress, video {
  display: inline-block;
  vertical-align: baseline; }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

.vertical-middle {
  position: relative;
  top: 50%;
  transform: translate(0, -50%); }

/*============================================================================
  #Grid Setup
    - Based on csswizardry grid, but with floated columns and a fixed gutter size
    - Breakpoints defined above, under #Breakpoint and Grid Variables
    - Note the inclusion of .grid-uniform to take care of clearfixes on evenly sized grid-items
==============================================================================*/
/* Force clearfix on grids */
.grid:after, .grid--rev:after, .grid--full:after,
.grid-uniform:after {
  content: "";
  display: table;
  clear: both; }

/* Manual grid-item clearfix */
.grid-item.clear {
  clear: both; }

/*============================================================================
  Drop relative positioning into silent classes which can't take advantage of
  the `[class*="push--"]` and `[class*="pull--"]` selectors.
==============================================================================*/
/*============================================================================
  Grid Setup
    1. Allow the grid system to be used on lists.
    2. Remove any margins and paddings that might affect the grid system.
    3. Apply a negative `margin-left` to negate the columns' gutters.
==============================================================================*/
.grid, .grid--rev, .grid--full,
.grid-uniform {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-left: -30px; }

.grid-item {
  float: left;
  padding-left: 30px;
  vertical-align: top;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

/*============================================================================
  Reversed grids allow you to structure your source in the opposite
  order to how your rendered layout will appear.
==============================================================================*/
.grid--rev {
  direction: rtl;
  text-align: left; }
  .grid--rev > .grid-item {
    direction: ltr;
    text-align: left;
    float: right; }

/* Gutterless grids have all the properties of regular grids, minus any spacing. */
.grid--full {
  margin-left: 0; }
  .grid--full > .grid-item {
    padding-left: 0; }

.blog-grid-item {
  float: left;
  padding: 0 4px 0 4px;
  width: 100%; }

/*============================================================================
  WIDTHS
    - Create width classes, prefixed by the specified namespace.
==============================================================================*/
/*================ Clearfix helper on uniform grids ================*/
/*============================================================================
  HELPERS
==============================================================================*/
/*================ Helper show/hide classes around our breakpoints ================*/
/*================ Our regular, non-responsive width and helper classes ================*/
/** Whole */
.one-whole {
  width: 100%; }

/* Halves */
.one-half, .two-quarters, .three-sixths, .four-eighths, .five-tenths, .six-twelfths {
  width: 50%; }

/* Thirds */
.one-third, .two-sixths, .four-twelfths {
  width: 33.333%; }

.two-thirds, .four-sixths, .eight-twelfths {
  width: 66.666%; }

/* Quarters */
.one-quarter, .two-eighths, .three-twelfths {
  width: 25%; }

.three-quarters, .six-eighths, .nine-twelfths {
  width: 75%; }

/* Fifths */
.one-fifth, .two-tenths {
  width: 20%; }

.two-fifths, .four-tenths {
  width: 40%; }

.three-fifths, .six-tenths {
  width: 60%; }

.four-fifths, .eight-tenths {
  width: 80%; }

/* Sixths */
.one-sixth, .two-twelfths {
  width: 16.666%; }

.five-sixths, .ten-twelfths {
  width: 83.333%; }

/* Eighths */
.one-eighth {
  width: 12.5%; }

.three-eighths {
  width: 37.5%; }

.five-eighths {
  width: 62.5%; }

.seven-eighths {
  width: 87.5%; }

/* Tenths */
.one-tenth {
  width: 10%; }

.three-tenths {
  width: 30%; }

.seven-tenths {
  width: 70%; }

.nine-tenths {
  width: 90%; }

/* Twelfths */
.one-twelfth {
  width: 8.333%; }

.five-twelfths {
  width: 41.666%; }

.seven-twelfths {
  width: 58.333%; }

.eleven-twelfths {
  width: 91.666%; }

.show {
  display: block !important; }

.hide {
  display: none !important; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.left {
  float: left !important; }

.right {
  float: right !important; }

/*================ Our responsive classes, if we have enabled them ================*/
@media only screen and (max-width: 480px) {
  /** Whole */
  .small--one-whole {
    width: 100%; }

  /* Halves */
  .small--one-half, .small--two-quarters, .small--three-sixths, .small--four-eighths, .small--five-tenths, .small--six-twelfths {
    width: 50%; }

  /* Thirds */
  .small--one-third, .small--two-sixths, .small--four-twelfths {
    width: 33.333%; }

  .small--two-thirds, .small--four-sixths, .small--eight-twelfths {
    width: 66.666%; }

  /* Quarters */
  .small--one-quarter, .small--two-eighths, .small--three-twelfths {
    width: 25%; }

  .small--three-quarters, .small--six-eighths, .small--nine-twelfths {
    width: 75%; }

  /* Fifths */
  .small--one-fifth, .small--two-tenths {
    width: 20%; }

  .small--two-fifths, .small--four-tenths {
    width: 40%; }

  .small--three-fifths, .small--six-tenths {
    width: 60%; }

  .small--four-fifths, .small--eight-tenths {
    width: 80%; }

  /* Sixths */
  .small--one-sixth, .small--two-twelfths {
    width: 16.666%; }

  .small--five-sixths, .small--ten-twelfths {
    width: 83.333%; }

  /* Eighths */
  .small--one-eighth {
    width: 12.5%; }

  .small--three-eighths {
    width: 37.5%; }

  .small--five-eighths {
    width: 62.5%; }

  .small--seven-eighths {
    width: 87.5%; }

  /* Tenths */
  .small--one-tenth {
    width: 10%; }

  .small--three-tenths {
    width: 30%; }

  .small--seven-tenths {
    width: 70%; }

  .small--nine-tenths {
    width: 90%; }

  /* Twelfths */
  .small--one-twelfth {
    width: 8.333%; }

  .small--five-twelfths {
    width: 41.666%; }

  .small--seven-twelfths {
    width: 58.333%; }

  .small--eleven-twelfths {
    width: 91.666%; }

  .small--show {
    display: block !important; }

  .small--hide {
    display: none !important; }

  .small--text-left {
    text-align: left !important; }

  .small--text-right {
    text-align: right !important; }

  .small--text-center {
    text-align: center !important; }

  .small--left {
    float: left !important; }

  .small--right {
    float: right !important; }

  .grid-uniform .small--one-half:nth-child(2n+1), .grid-uniform .small--two-quarters:nth-child(2n+1), .grid-uniform .small--three-sixths:nth-child(2n+1), .grid-uniform .small--four-eighths:nth-child(2n+1), .grid-uniform .small--five-tenths:nth-child(2n+1), .grid-uniform .small--six-twelfths:nth-child(2n+1),
  .grid-uniform .small--one-third:nth-child(3n+1),
  .grid-uniform .small--two-sixths:nth-child(3n+1),
  .grid-uniform .small--four-twelfths:nth-child(3n+1),
  .grid-uniform .small--one-quarter:nth-child(4n+1),
  .grid-uniform .small--two-eighths:nth-child(4n+1),
  .grid-uniform .small--three-twelfths:nth-child(4n+1),
  .grid-uniform .small--one-fifth:nth-child(5n+1),
  .grid-uniform .small--two-tenths:nth-child(5n+1),
  .grid-uniform .small--one-sixth:nth-child(6n+1),
  .grid-uniform .small--two-twelfths:nth-child(6n+1),
  .grid-uniform .small--two-sixths:nth-child(3n+1),
  .grid-uniform .small--three-sixths:nth-child(2n+1),
  .grid-uniform .small--two-eighths:nth-child(4n+1),
  .grid-uniform .small--four-eighths:nth-child(2n+1),
  .grid-uniform .small--five-tenths:nth-child(2n+1),
  .grid-uniform .small--one-twelfth:nth-child(12n+1),
  .grid-uniform .small--two-twelfths:nth-child(6n+1),
  .grid-uniform .small--three-twelfths:nth-child(4n+1),
  .grid-uniform .small--four-twelfths:nth-child(3n+1),
  .grid-uniform .small--six-twelfths:nth-child(2n+1) {
    clear: both; } }
@media only screen and (min-width: 481px) and (max-width: 768px) {
  /** Whole */
  .medium--one-whole {
    width: 100%; }

  /* Halves */
  .medium--one-half, .medium--two-quarters, .medium--three-sixths, .medium--four-eighths, .medium--five-tenths, .medium--six-twelfths {
    width: 50%; }

  /* Thirds */
  .medium--one-third, .medium--two-sixths, .medium--four-twelfths {
    width: 33.333%; }

  .medium--two-thirds, .medium--four-sixths, .medium--eight-twelfths {
    width: 66.666%; }

  /* Quarters */
  .medium--one-quarter, .medium--two-eighths, .medium--three-twelfths {
    width: 25%; }

  .medium--three-quarters, .medium--six-eighths, .medium--nine-twelfths {
    width: 75%; }

  /* Fifths */
  .medium--one-fifth, .medium--two-tenths {
    width: 20%; }

  .medium--two-fifths, .medium--four-tenths {
    width: 40%; }

  .medium--three-fifths, .medium--six-tenths {
    width: 60%; }

  .medium--four-fifths, .medium--eight-tenths {
    width: 80%; }

  /* Sixths */
  .medium--one-sixth, .medium--two-twelfths {
    width: 16.666%; }

  .medium--five-sixths, .medium--ten-twelfths {
    width: 83.333%; }

  /* Eighths */
  .medium--one-eighth {
    width: 12.5%; }

  .medium--three-eighths {
    width: 37.5%; }

  .medium--five-eighths {
    width: 62.5%; }

  .medium--seven-eighths {
    width: 87.5%; }

  /* Tenths */
  .medium--one-tenth {
    width: 10%; }

  .medium--three-tenths {
    width: 30%; }

  .medium--seven-tenths {
    width: 70%; }

  .medium--nine-tenths {
    width: 90%; }

  /* Twelfths */
  .medium--one-twelfth {
    width: 8.333%; }

  .medium--five-twelfths {
    width: 41.666%; }

  .medium--seven-twelfths {
    width: 58.333%; }

  .medium--eleven-twelfths {
    width: 91.666%; }

  .medium--show {
    display: block !important; }

  .medium--hide {
    display: none !important; }

  .medium--text-left {
    text-align: left !important; }

  .medium--text-right {
    text-align: right !important; }

  .medium--text-center {
    text-align: center !important; }

  .medium--left {
    float: left !important; }

  .medium--right {
    float: right !important; }

  .grid-uniform .medium--one-half:nth-child(2n+1), .grid-uniform .medium--two-quarters:nth-child(2n+1), .grid-uniform .medium--three-sixths:nth-child(2n+1), .grid-uniform .medium--four-eighths:nth-child(2n+1), .grid-uniform .medium--five-tenths:nth-child(2n+1), .grid-uniform .medium--six-twelfths:nth-child(2n+1),
  .grid-uniform .medium--one-third:nth-child(3n+1),
  .grid-uniform .medium--two-sixths:nth-child(3n+1),
  .grid-uniform .medium--four-twelfths:nth-child(3n+1),
  .grid-uniform .medium--one-quarter:nth-child(4n+1),
  .grid-uniform .medium--two-eighths:nth-child(4n+1),
  .grid-uniform .medium--three-twelfths:nth-child(4n+1),
  .grid-uniform .medium--one-fifth:nth-child(5n+1),
  .grid-uniform .medium--two-tenths:nth-child(5n+1),
  .grid-uniform .medium--one-sixth:nth-child(6n+1),
  .grid-uniform .medium--two-twelfths:nth-child(6n+1),
  .grid-uniform .medium--two-sixths:nth-child(3n+1),
  .grid-uniform .medium--three-sixths:nth-child(2n+1),
  .grid-uniform .medium--two-eighths:nth-child(4n+1),
  .grid-uniform .medium--four-eighths:nth-child(2n+1),
  .grid-uniform .medium--five-tenths:nth-child(2n+1),
  .grid-uniform .medium--one-twelfth:nth-child(12n+1),
  .grid-uniform .medium--two-twelfths:nth-child(6n+1),
  .grid-uniform .medium--three-twelfths:nth-child(4n+1),
  .grid-uniform .medium--four-twelfths:nth-child(3n+1),
  .grid-uniform .medium--six-twelfths:nth-child(2n+1) {
    clear: both; } }
@media only screen and (max-width: 768px) {
  /** Whole */
  .medium-down--one-whole {
    width: 100%; }

  /* Halves */
  .medium-down--one-half, .medium-down--two-quarters, .medium-down--three-sixths, .medium-down--four-eighths, .medium-down--five-tenths, .medium-down--six-twelfths {
    width: 50%; }

  /* Thirds */
  .medium-down--one-third, .medium-down--two-sixths, .medium-down--four-twelfths {
    width: 33.333%; }

  .medium-down--two-thirds, .medium-down--four-sixths, .medium-down--eight-twelfths {
    width: 66.666%; }

  /* Quarters */
  .medium-down--one-quarter, .medium-down--two-eighths, .medium-down--three-twelfths {
    width: 25%; }

  .medium-down--three-quarters, .medium-down--six-eighths, .medium-down--nine-twelfths {
    width: 75%; }

  /* Fifths */
  .medium-down--one-fifth, .medium-down--two-tenths {
    width: 20%; }

  .medium-down--two-fifths, .medium-down--four-tenths {
    width: 40%; }

  .medium-down--three-fifths, .medium-down--six-tenths {
    width: 60%; }

  .medium-down--four-fifths, .medium-down--eight-tenths {
    width: 80%; }

  /* Sixths */
  .medium-down--one-sixth, .medium-down--two-twelfths {
    width: 16.666%; }

  .medium-down--five-sixths, .medium-down--ten-twelfths {
    width: 83.333%; }

  /* Eighths */
  .medium-down--one-eighth {
    width: 12.5%; }

  .medium-down--three-eighths {
    width: 37.5%; }

  .medium-down--five-eighths {
    width: 62.5%; }

  .medium-down--seven-eighths {
    width: 87.5%; }

  /* Tenths */
  .medium-down--one-tenth {
    width: 10%; }

  .medium-down--three-tenths {
    width: 30%; }

  .medium-down--seven-tenths {
    width: 70%; }

  .medium-down--nine-tenths {
    width: 90%; }

  /* Twelfths */
  .medium-down--one-twelfth {
    width: 8.333%; }

  .medium-down--five-twelfths {
    width: 41.666%; }

  .medium-down--seven-twelfths {
    width: 58.333%; }

  .medium-down--eleven-twelfths {
    width: 91.666%; }

  .medium-down--show {
    display: block !important; }

  .medium-down--hide {
    display: none !important; }

  .medium-down--text-left {
    text-align: left !important; }

  .medium-down--text-right {
    text-align: right !important; }

  .medium-down--text-center {
    text-align: center !important; }

  .medium-down--left {
    float: left !important; }

  .medium-down--right {
    float: right !important; }

  .grid-uniform .medium-down--one-half:nth-child(2n+1), .grid-uniform .medium-down--two-quarters:nth-child(2n+1), .grid-uniform .medium-down--three-sixths:nth-child(2n+1), .grid-uniform .medium-down--four-eighths:nth-child(2n+1), .grid-uniform .medium-down--five-tenths:nth-child(2n+1), .grid-uniform .medium-down--six-twelfths:nth-child(2n+1),
  .grid-uniform .medium-down--one-third:nth-child(3n+1),
  .grid-uniform .medium-down--two-sixths:nth-child(3n+1),
  .grid-uniform .medium-down--four-twelfths:nth-child(3n+1),
  .grid-uniform .medium-down--one-quarter:nth-child(4n+1),
  .grid-uniform .medium-down--two-eighths:nth-child(4n+1),
  .grid-uniform .medium-down--three-twelfths:nth-child(4n+1),
  .grid-uniform .medium-down--one-fifth:nth-child(5n+1),
  .grid-uniform .medium-down--two-tenths:nth-child(5n+1),
  .grid-uniform .medium-down--one-sixth:nth-child(6n+1),
  .grid-uniform .medium-down--two-twelfths:nth-child(6n+1),
  .grid-uniform .medium-down--two-sixths:nth-child(3n+1),
  .grid-uniform .medium-down--three-sixths:nth-child(2n+1),
  .grid-uniform .medium-down--two-eighths:nth-child(4n+1),
  .grid-uniform .medium-down--four-eighths:nth-child(2n+1),
  .grid-uniform .medium-down--five-tenths:nth-child(2n+1),
  .grid-uniform .medium-down--one-twelfth:nth-child(12n+1),
  .grid-uniform .medium-down--two-twelfths:nth-child(6n+1),
  .grid-uniform .medium-down--three-twelfths:nth-child(4n+1),
  .grid-uniform .medium-down--four-twelfths:nth-child(3n+1),
  .grid-uniform .medium-down--six-twelfths:nth-child(2n+1) {
    clear: both; } }
@media only screen and (min-width: 769px) {
  /** Whole */
  .large--one-whole {
    width: 100%; }

  /* Halves */
  .large--one-half, .large--two-quarters, .large--three-sixths, .large--four-eighths, .large--five-tenths, .large--six-twelfths {
    width: 50%; }

  /* Thirds */
  .large--one-third, .large--two-sixths, .large--four-twelfths {
    width: 33.333%; }

  .large--two-thirds, .large--four-sixths, .large--eight-twelfths {
    width: 66.666%; }

  /* Quarters */
  .large--one-quarter, .large--two-eighths, .large--three-twelfths {
    width: 25%; }

  .large--three-quarters, .large--six-eighths, .large--nine-twelfths {
    width: 75%; }

  /* Fifths */
  .large--one-fifth, .large--two-tenths {
    width: 20%; }

  .large--two-fifths, .large--four-tenths {
    width: 40%; }

  .large--three-fifths, .large--six-tenths {
    width: 60%; }

  .large--four-fifths, .large--eight-tenths {
    width: 80%; }

  /* Sixths */
  .large--one-sixth, .large--two-twelfths {
    width: 16.666%; }

  .large--five-sixths, .large--ten-twelfths {
    width: 83.333%; }

  /* Eighths */
  .large--one-eighth {
    width: 12.5%; }

  .large--three-eighths {
    width: 37.5%; }

  .large--five-eighths {
    width: 62.5%; }

  .large--seven-eighths {
    width: 87.5%; }

  /* Tenths */
  .large--one-tenth {
    width: 10%; }

  .large--three-tenths {
    width: 30%; }

  .large--seven-tenths {
    width: 70%; }

  .large--nine-tenths {
    width: 90%; }

  /* Twelfths */
  .large--one-twelfth {
    width: 8.333%; }

  .large--five-twelfths {
    width: 41.666%; }

  .large--seven-twelfths {
    width: 58.333%; }

  .large--eleven-twelfths {
    width: 91.666%; }

  .large--show {
    display: block !important; }

  .large--hide {
    display: none !important; }

  .large--text-left {
    text-align: left !important; }

  .large--text-right {
    text-align: right !important; }

  .large--text-center {
    text-align: center !important; }

  .large--left {
    float: left !important; }

  .large--right {
    float: right !important; }

  .grid-uniform .large--one-half:nth-child(2n+1), .grid-uniform .large--two-quarters:nth-child(2n+1), .grid-uniform .large--three-sixths:nth-child(2n+1), .grid-uniform .large--four-eighths:nth-child(2n+1), .grid-uniform .large--five-tenths:nth-child(2n+1), .grid-uniform .large--six-twelfths:nth-child(2n+1),
  .grid-uniform .large--one-third:nth-child(3n+1),
  .grid-uniform .large--two-sixths:nth-child(3n+1),
  .grid-uniform .large--four-twelfths:nth-child(3n+1),
  .grid-uniform .large--one-quarter:nth-child(4n+1),
  .grid-uniform .large--two-eighths:nth-child(4n+1),
  .grid-uniform .large--three-twelfths:nth-child(4n+1),
  .grid-uniform .large--one-fifth:nth-child(5n+1),
  .grid-uniform .large--two-tenths:nth-child(5n+1),
  .grid-uniform .large--one-sixth:nth-child(6n+1),
  .grid-uniform .large--two-twelfths:nth-child(6n+1),
  .grid-uniform .large--two-sixths:nth-child(3n+1),
  .grid-uniform .large--three-sixths:nth-child(2n+1),
  .grid-uniform .large--two-eighths:nth-child(4n+1),
  .grid-uniform .large--four-eighths:nth-child(2n+1),
  .grid-uniform .large--five-tenths:nth-child(2n+1),
  .grid-uniform .large--one-twelfth:nth-child(12n+1),
  .grid-uniform .large--two-twelfths:nth-child(6n+1),
  .grid-uniform .large--three-twelfths:nth-child(4n+1),
  .grid-uniform .large--four-twelfths:nth-child(3n+1),
  .grid-uniform .large--six-twelfths:nth-child(2n+1) {
    clear: both; } }
/*============================================================================
  PUSH
    - Push classes, to move grid items over to the right by certain amounts
==============================================================================*/
[class*="push--"] {
  position: relative; }

/* Whole */
.push--one-whole {
  left: 100%; }

/* Halves */
.push--one-half, .push--two-quarters, .push--three-sixths, .push--four-eighths, .push--five-tenths, .push--six-twelfths {
  left: 50%; }

/* Thirds */
.push--one-third, .push--two-sixths, .push--four-twelfths {
  left: 33.333%; }

.push--two-thirds, .push--four-sixths, .push--eight-twelfths {
  left: 66.666%; }

/* Quarters */
.push--one-quarter, .push--two-eighths, .push--three-twelfths {
  left: 25%; }

.push--three-quarters, .push--six-eighths, .push--nine-twelfths {
  left: 75%; }

/* Fifths */
.push--one-fifth, .push--two-tenths {
  left: 20%; }

.push--two-fifths, .push--four-tenths {
  left: 40%; }

.push--three-fifths, .push--six-tenths {
  left: 60%; }

.push--four-fifths, .push--eight-tenths {
  left: 80%; }

/* Sixths */
.push--one-sixth, .push--two-twelfths {
  left: 16.666%; }

.push--five-sixths, .push--ten-twelfths {
  left: 83.333%; }

/* Eighths */
.push--one-eighth {
  left: 12.5%; }

.push--three-eighths {
  left: 37.5%; }

.push--five-eighths {
  left: 62.5%; }

.push--seven-eighths {
  left: 87.5%; }

/* Tenths */
.push--one-tenth {
  left: 10%; }

.push--three-tenths {
  left: 30%; }

.push--seven-tenths {
  left: 70%; }

.push--nine-tenths {
  left: 90%; }

/* Twelfths */
.push--one-twelfth {
  left: 8.333%; }

.push--five-twelfths {
  left: 41.666%; }

.push--seven-twelfths {
  left: 58.333%; }

.push--eleven-twelfths {
  left: 91.666%; }

@media only screen and (min-width: 481px) and (max-width: 768px) {
  /* Whole */
  .push--medium--one-whole {
    left: 100%; }

  /* Halves */
  .push--medium--one-half, .push--medium--two-quarters, .push--medium--three-sixths, .push--medium--four-eighths, .push--medium--five-tenths, .push--medium--six-twelfths {
    left: 50%; }

  /* Thirds */
  .push--medium--one-third, .push--medium--two-sixths, .push--medium--four-twelfths {
    left: 33.333%; }

  .push--medium--two-thirds, .push--medium--four-sixths, .push--medium--eight-twelfths {
    left: 66.666%; }

  /* Quarters */
  .push--medium--one-quarter, .push--medium--two-eighths, .push--medium--three-twelfths {
    left: 25%; }

  .push--medium--three-quarters, .push--medium--six-eighths, .push--medium--nine-twelfths {
    left: 75%; }

  /* Fifths */
  .push--medium--one-fifth, .push--medium--two-tenths {
    left: 20%; }

  .push--medium--two-fifths, .push--medium--four-tenths {
    left: 40%; }

  .push--medium--three-fifths, .push--medium--six-tenths {
    left: 60%; }

  .push--medium--four-fifths, .push--medium--eight-tenths {
    left: 80%; }

  /* Sixths */
  .push--medium--one-sixth, .push--medium--two-twelfths {
    left: 16.666%; }

  .push--medium--five-sixths, .push--medium--ten-twelfths {
    left: 83.333%; }

  /* Eighths */
  .push--medium--one-eighth {
    left: 12.5%; }

  .push--medium--three-eighths {
    left: 37.5%; }

  .push--medium--five-eighths {
    left: 62.5%; }

  .push--medium--seven-eighths {
    left: 87.5%; }

  /* Tenths */
  .push--medium--one-tenth {
    left: 10%; }

  .push--medium--three-tenths {
    left: 30%; }

  .push--medium--seven-tenths {
    left: 70%; }

  .push--medium--nine-tenths {
    left: 90%; }

  /* Twelfths */
  .push--medium--one-twelfth {
    left: 8.333%; }

  .push--medium--five-twelfths {
    left: 41.666%; }

  .push--medium--seven-twelfths {
    left: 58.333%; }

  .push--medium--eleven-twelfths {
    left: 91.666%; } }
@media only screen and (max-width: 768px) {
  /* Whole */
  .push--medium-down--one-whole {
    left: 100%; }

  /* Halves */
  .push--medium-down--one-half, .push--medium-down--two-quarters, .push--medium-down--three-sixths, .push--medium-down--four-eighths, .push--medium-down--five-tenths, .push--medium-down--six-twelfths {
    left: 50%; }

  /* Thirds */
  .push--medium-down--one-third, .push--medium-down--two-sixths, .push--medium-down--four-twelfths {
    left: 33.333%; }

  .push--medium-down--two-thirds, .push--medium-down--four-sixths, .push--medium-down--eight-twelfths {
    left: 66.666%; }

  /* Quarters */
  .push--medium-down--one-quarter, .push--medium-down--two-eighths, .push--medium-down--three-twelfths {
    left: 25%; }

  .push--medium-down--three-quarters, .push--medium-down--six-eighths, .push--medium-down--nine-twelfths {
    left: 75%; }

  /* Fifths */
  .push--medium-down--one-fifth, .push--medium-down--two-tenths {
    left: 20%; }

  .push--medium-down--two-fifths, .push--medium-down--four-tenths {
    left: 40%; }

  .push--medium-down--three-fifths, .push--medium-down--six-tenths {
    left: 60%; }

  .push--medium-down--four-fifths, .push--medium-down--eight-tenths {
    left: 80%; }

  /* Sixths */
  .push--medium-down--one-sixth, .push--medium-down--two-twelfths {
    left: 16.666%; }

  .push--medium-down--five-sixths, .push--medium-down--ten-twelfths {
    left: 83.333%; }

  /* Eighths */
  .push--medium-down--one-eighth {
    left: 12.5%; }

  .push--medium-down--three-eighths {
    left: 37.5%; }

  .push--medium-down--five-eighths {
    left: 62.5%; }

  .push--medium-down--seven-eighths {
    left: 87.5%; }

  /* Tenths */
  .push--medium-down--one-tenth {
    left: 10%; }

  .push--medium-down--three-tenths {
    left: 30%; }

  .push--medium-down--seven-tenths {
    left: 70%; }

  .push--medium-down--nine-tenths {
    left: 90%; }

  /* Twelfths */
  .push--medium-down--one-twelfth {
    left: 8.333%; }

  .push--medium-down--five-twelfths {
    left: 41.666%; }

  .push--medium-down--seven-twelfths {
    left: 58.333%; }

  .push--medium-down--eleven-twelfths {
    left: 91.666%; } }
@media only screen and (min-width: 769px) {
  /* Whole */
  .push--large--one-whole {
    left: 100%; }

  /* Halves */
  .push--large--one-half, .push--large--two-quarters, .push--large--three-sixths, .push--large--four-eighths, .push--large--five-tenths, .push--large--six-twelfths {
    left: 50%; }

  /* Thirds */
  .push--large--one-third, .push--large--two-sixths, .push--large--four-twelfths {
    left: 33.333%; }

  .push--large--two-thirds, .push--large--four-sixths, .push--large--eight-twelfths {
    left: 66.666%; }

  /* Quarters */
  .push--large--one-quarter, .push--large--two-eighths, .push--large--three-twelfths {
    left: 25%; }

  .push--large--three-quarters, .push--large--six-eighths, .push--large--nine-twelfths {
    left: 75%; }

  /* Fifths */
  .push--large--one-fifth, .push--large--two-tenths {
    left: 20%; }

  .push--large--two-fifths, .push--large--four-tenths {
    left: 40%; }

  .push--large--three-fifths, .push--large--six-tenths {
    left: 60%; }

  .push--large--four-fifths, .push--large--eight-tenths {
    left: 80%; }

  /* Sixths */
  .push--large--one-sixth, .push--large--two-twelfths {
    left: 16.666%; }

  .push--large--five-sixths, .push--large--ten-twelfths {
    left: 83.333%; }

  /* Eighths */
  .push--large--one-eighth {
    left: 12.5%; }

  .push--large--three-eighths {
    left: 37.5%; }

  .push--large--five-eighths {
    left: 62.5%; }

  .push--large--seven-eighths {
    left: 87.5%; }

  /* Tenths */
  .push--large--one-tenth {
    left: 10%; }

  .push--large--three-tenths {
    left: 30%; }

  .push--large--seven-tenths {
    left: 70%; }

  .push--large--nine-tenths {
    left: 90%; }

  /* Twelfths */
  .push--large--one-twelfth {
    left: 8.333%; }

  .push--large--five-twelfths {
    left: 41.666%; }

  .push--large--seven-twelfths {
    left: 58.333%; }

  .push--large--eleven-twelfths {
    left: 91.666%; } }
/*============================================================================
  PULL
    - Pull classes, to move grid items back to the left by certain amounts
==============================================================================*/
/*================ IE8 grid uniform clearfix ================*/
.lt-ie9 .grid-uniform {
  text-align: left;
  margin-left: 0; }
  .lt-ie9 .grid-uniform .grid-item {
    display: inline-block;
    float: none;
    padding: 0;
    margin-left: -3px; }

/*================ Home page section helpers ================*/
.template-index main.main-content .shopify-section:nth-child(1) hr:first-child {
  display: none; }

/*============================================================================
  #Basic Styles
==============================================================================*/
html, body {
  padding: 0;
  margin: 0;
  background-color: #eaeded; }
  html.js .no-js, body.js .no-js {
    display: block; }
  html.no-js .no-js, body.no-js .no-js {
    display: none !important; }

/*============================================================================
  Commented out CSS for background for easy background image uploading in case customers ask.
  Simply upload a file called 'bg.jpg' and delete lines as necessary.
==============================================================================*/
.wrapper {
  max-width: 1060px;
  margin: 0 auto;
  padding: 0 15px; }
  .wrapper:after {
    content: "";
    display: table;
    clear: both; }
  @media screen and (min-width: 481px) {
    .wrapper {
      padding: 0 30px; } }

.main-content {
  display: block;
  padding-top: 30px;
  padding-bottom: 0px;
  background-color: #FFFFFF; }

.home-content {
  padding-top: 0px; }

.grid-border > .grid-item {
  padding-top: 30px; }

@media screen and (min-width: 769px) {
  .grid-border--right {
    border-right: 1px solid #dedede; }

  .grid-border--left {
    border-left: 1px solid #dedede; } }
/*============================================================================
  #Helper Classes
==============================================================================*/
.left {
  float: left; }

.right {
  float: right; }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

.hidden {
  display: none; }

.display-table {
  display: table;
  table-layout: fixed;
  width: 100%; }

.display-table-cell {
  display: table-cell;
  vertical-align: middle;
  float: none; }

@media screen and (min-width: 769px) {
  .large--display-table {
    display: table;
    table-layout: fixed;
    width: 100%; }

  .large--display-table-cell {
    display: table-cell;
    vertical-align: middle;
    float: none; } }
.visually-hidden {
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
  position: absolute;
  height: 1px;
  width: 1px; }

/*============================================================================
  #Typography
==============================================================================*/
body,
input,
textarea,
button,
select {
  font-size: 14px;
  line-height: 1.6;
  font-family: "Open Sans", "HelveticaNeue", "Helvetica Neue", sans-serif;
  color: black;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%; }

.payment-buttons {
  margin: 0 0 -30px 0; }

h1, .h1, #shopify-product-reviews .spr-header-title, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  display: block;
  margin: 0 0 0.5em;
  line-height: 1.4;
  font-family: "Open Sans", "HelveticaNeue", "Helvetica Neue", sans-serif;
  font-weight: 400; }
  h1 a, .h1 a, #shopify-product-reviews .spr-header-title a, h2 a, .h2 a, h3 a, .h3 a, h4 a, .h4 a, h5 a, .h5 a, h6 a, .h6 a {
    text-decoration: none;
    font-weight: inherit; }

/*================ Pixel to EM conversion tool - http://pxtoem.com/ ================*/
h1, .h1, #shopify-product-reviews .spr-header-title {
  font-size: 1.500em; }

h2, .h2 {
  font-size: 1.250em;
  font-weight: bold; }

h3, .h3 {
  font-size: 1.063em; }

h4, .h4 {
  font-size: 1em; }

h5, .h5 {
  font-size: 0.938em; }

h6, .h6 {
  font-size: 0.938em; }

.main-content h1, .main-content .h1, .main-content #shopify-product-reviews .spr-header-title, #shopify-product-reviews .main-content .spr-header-title, .main-content h2, .main-content .h2, .main-content h3, .main-content .h3, .main-content h4, .main-content .h4, .main-content h5, .main-content .h5 {
  color: black; }

.sidebar h2, .sidebar .h2, .sidebar h3, .sidebar .h3, .sidebar h4, .sidebar .h4, .sidebar h5, .sidebar .h5 {
  margin-top: 4px; }

p {
  margin: 0 0 15px 0; }
  p img {
    margin: 0; }

em {
  font-style: italic; }

b, strong {
  font-weight: bold; }

small {
  font-size: 80%; }

sup, sub {
  position: relative;
  font-size: 60%;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.5em; }

.text-light, s {
  color: black; }

/*================ Blockquotes ================*/
blockquote, q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: none; }

blockquote {
  font-size: 1.125em;
  line-height: 1.45;
  font-style: italic;
  margin: 0 0 30px;
  padding: 15px 30px;
  border-left: 1px solid #dedede; }
  blockquote p {
    margin-bottom: 0; }
    blockquote p + cite {
      margin-top: 15px; }
  blockquote cite {
    display: block;
    font-size: 0.75em; }
    blockquote cite:before {
      content: "\2014 \0020"; }

/*================ Code ================*/
code, pre {
  background-color: #faf7f5;
  font-family: Consolas,monospace;
  font-size: 1em;
  border: 0 none;
  padding: 0 2px;
  color: #51ab62; }

pre {
  overflow: auto;
  padding: 0.5em;
  margin: 0 0 1em; }

/*================ Horizontal Rules ================*/
hr {
  clear: both;
  height: 1px;
  border: none;
  color: #dedede;
  background-color: #dedede; }
  hr.hr--small {
    margin: 15px 0; }
  hr.hr--clear {
    border-top-color: transparent; }
  footer hr {
    border-top-color: #003600; }
  @media screen and (min-width: 769px) {
    hr.hr--offset-left {
      margin-left: -30px; } }

/*================ Section Headers ================*/
.section-header {
  margin-bottom: 20px; }

.section-header--title {
  margin: 0; }

@media screen and (min-width: 769px) {
  .section-header {
    display: table;
    width: 100%; }

  .section-header--left {
    display: table-cell;
    vertical-align: middle;
    margin-bottom: 0; }
    .section-header--left h1, .section-header--left .h1, .section-header--left #shopify-product-reviews .spr-header-title, #shopify-product-reviews .section-header--left .spr-header-title, .section-header--left h2, .section-header--left .h2, .section-header--left h3, .section-header--left .h3, .section-header--left h4, .section-header--left .h4,
    .section-header--left .h1, .section-header--left .h2, .section-header--left .h3, .section-header--left .h4 {
      margin-bottom: 0; }

  .section-header--right {
    display: table-cell;
    vertical-align: middle;
    text-align: right;
    width: 335px; } }
  @media screen and (min-width: 769px) and (max-width: 768px) {
    .section-header--right {
      margin-bottom: 30px; } }

.section-header--right .form-horizontal {
  display: inline-block;
  vertical-align: middle; }
@media screen and (min-width: 481px) {
  .section-header--right label + select,
  .section-header--right .collection-view {
    margin-left: 15px; } }

.collection-view {
  display: none;
  border: 1px solid #dedede;
  border-radius: 2px;
  padding: 11px;
  height: 39px;
  vertical-align: middle;
  /*================ We use SVG icons, so simply hide this in oldIE ================*/
  /*================ Only show on larger screens ================*/ }
  .lt-ie9 .collection-view {
    display: none; }
  @media screen and (min-width: 769px) {
    .collection-view {
      display: inline-block; } }
  .collection-view a {
    color: black;
    display: block;
    float: left;
    opacity: 0.7;
    cursor: pointer;
    line-height: 100%;
    -webkit-transition: opacity 0.15s ease-out;
    -moz-transition: opacity 0.15s ease-out;
    -ms-transition: opacity 0.15s ease-out;
    -o-transition: opacity 0.15s ease-out;
    transition: opacity 0.15s ease-out; }
    .collection-view a + a {
      margin-left: 10px; }
    .collection-view a:hover {
      opacity: 0.9;
      -webkit-transition: opacity 0.05s ease-in;
      -moz-transition: opacity 0.05s ease-in;
      -ms-transition: opacity 0.05s ease-in;
      -o-transition: opacity 0.05s ease-in;
      transition: opacity 0.05s ease-in; }
    .collection-view a.collection-view--active {
      opacity: 1;
      cursor: default; }
  .collection-view img {
    display: block;
    opacity: inherit; }

.newsletter-section {
  padding: 20px 0; }
  .newsletter-section .h1 {
    margin-bottom: 10px; }
  @media screen and (min-width: 769px) {
    .newsletter-section #contact_form {
      width: 50%;
      margin: 0 auto; } }

/*============================================================================
  #Rich Text Editor
==============================================================================*/
.rte {
  margin-bottom: 15px; }
  .rte img {
    max-width: 100%;
    height: auto; }
  .rte h1, .rte .h1, .rte #shopify-product-reviews .spr-header-title, #shopify-product-reviews .rte .spr-header-title, .rte h2, .rte .h2, .rte h3, .rte .h3, .rte h4, .rte .h4, .rte h5, .rte .h5, .rte h6, .rte .h6 {
    margin-top: 0.5em; }
    .rte h1:first-child, .rte .h1:first-child, .rte #shopify-product-reviews .spr-header-title:first-child, #shopify-product-reviews .rte .spr-header-title:first-child, .rte h2:first-child, .rte .h2:first-child, .rte h3:first-child, .rte .h3:first-child, .rte h4:first-child, .rte .h4:first-child, .rte h5:first-child, .rte .h5:first-child, .rte h6:first-child, .rte .h6:first-child {
      margin-top: 0; }
  .rte > div {
    margin-bottom: 15px; }
  .rte ul, .rte ol {
    margin-left: 35px; }
  .rte ul ul {
    list-style: circle outside; }
  .rte li {
    margin-bottom: 0.4em; }
  .rte table {
    table-layout: fixed; }

.rte--header {
  margin-bottom: 0; }

/*============================================================================
  #Links and Buttons
==============================================================================*/
.cart_btns {
  text-align: right; }

.paypal-button {
  max-width: 150px;
  float: right; }

.checkout_btn {
  height: 42px;
  text-align: right; }

.update_btn {
  color: #CCCCCC;
  background: #CCCCCC;
  max-width: 150px;
  text-align: left; }

a {
  color: #008200;
  text-decoration: none;
  background: transparent; }

a:hover,
a:focus {
  color: #00ce00; }

button {
  overflow: visible; }

button[disabled],
html input[disabled] {
  cursor: default; }

.btn, .btn-secondary,
input.btn-secondary, input[type="submit"], .header-cart-btn {
  display: inline-block;
  padding: 8px 20px;
  margin: 0;
  line-height: 1.42;
  text-decoration: none;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  cursor: pointer;
  border: 1px solid transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 2px;
  font-family: "Roboto", "HelveticaNeue", "Helvetica Neue", sans-serif;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  -webkit-transition: background-color 0.2s ease-out;
  -moz-transition: background-color 0.2s ease-out;
  -ms-transition: background-color 0.2s ease-out;
  -o-transition: background-color 0.2s ease-out;
  transition: background-color 0.2s ease-out;
  /*================ Set primary button colors - can override later ================*/
  background-color: #008200;
  color: white; }
  .btn.btn--small, .btn--small.btn-secondary, input.btn--small[type="submit"], .btn--small.header-cart-btn {
    padding: 8px 12px 7px;
    font-size: 12px; }
  .btn.btn--wide, .btn--wide.btn-secondary, input.btn--wide[type="submit"], .btn--wide.header-cart-btn {
    min-width: 40%; }
    @media screen and (max-width: 768px) {
      .btn.btn--wide, .btn--wide.btn-secondary, input.btn--wide[type="submit"], .btn--wide.header-cart-btn {
        width: 100%; } }
  .btn.btn--full, .btn--full.btn-secondary, input.btn--full[type="submit"], .btn--full.header-cart-btn {
    width: 100%; }
  .btn:hover, .btn-secondary:hover, input[type="submit"]:hover, .header-cart-btn:hover {
    background-color: #006900;
    color: white;
    -webkit-transition: background-color 0.05s ease-in;
    -moz-transition: background-color 0.05s ease-in;
    -ms-transition: background-color 0.05s ease-in;
    -o-transition: background-color 0.05s ease-in;
    transition: background-color 0.05s ease-in; }
  .btn:active, .btn-secondary:active, input[type="submit"]:active, .header-cart-btn:active {
    background-color: #004f00;
    color: white; }
  .btn[disabled], [disabled].btn-secondary, input[disabled][type="submit"], [disabled].header-cart-btn, .btn.disabled, .disabled.btn-secondary, input.disabled[type="submit"], .disabled.header-cart-btn {
    cursor: default;
    color: #b6b6b6;
    background-color: #f6f6f6; }

.btn-secondary,
input.btn-secondary {
  background-color: #f3f3f3;
  color: black; }
  .btn-secondary:hover,
  input.btn-secondary:hover {
    background-color: #e6e6e6;
    color: black; }
  .btn-secondary:active,
  input.btn-secondary:active {
    background-color: #d9d9d9;
    color: black; }

/*================ Force an input/button to look like a text link ================*/
.text-link {
  display: inline;
  border: 0 none;
  background: none;
  padding: 0;
  margin: 0; }

/*============================================================================
  #Lists
==============================================================================*/
ul, ol {
  margin: 0 0 1em;
  padding: 0; }

ul {
  list-style: none outside; }

ol {
  list-style: decimal; }

ol, ul.square, .rte ul ul ul, ul.disc, .rte ul {
  margin-left: 20px; }

ul.square, .rte ul ul ul {
  list-style: square outside; }

ul.disc, .rte ul {
  list-style: disc outside; }

ol.alpha {
  list-style: lower-alpha outside; }

ul ul, ul ol,
ol ol, ol ul {
  margin: 4px 0 5px 20px; }

li {
  margin-bottom: 0.58em; }

.inline-list li {
  display: inline-block;
  margin-bottom: 0; }

/*============================================================================
  #Tables
==============================================================================*/
table {
  border-collapse: collapse;
  border-spacing: 0;
  min-width: 50%; }

table.full {
  width: 100%;
  margin-bottom: 15px; }

th {
  font-weight: bold; }

th, td {
  text-align: left;
  padding: 7.5px;
  border: 1px solid #dedede; }

.media,
.media-flex {
  overflow: hidden;
  _overflow: visible;
  zoom: 1; }

.media-img {
  float: left;
  margin-right: 30px; }

.media-img-right {
  float: right;
  margin-left: 30px; }

.media-img img,
.media-img-right img {
  display: block; }

/*============================================================================
  #Images and Iframes
==============================================================================*/
img {
  border: 0 none; }

svg:not(:root) {
  overflow: hidden; }

img.auto,
.grid-item img,
.grid-item iframe {
  max-width: 100%; }

.video-wrapper {
  position: relative;
  overflow: hidden;
  max-width: 100%;
  padding-bottom: 56.25%;
  height: 0;
  height: auto; }
  .video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

/*============================================================================
  #Forms
==============================================================================*/
form {
  margin-bottom: 30px; }

button,
input[type="text"],
input[type="search"],
input[type="password"],
input[type="email"],
input[type="file"],
input[type="number"],
input[type="tel"],
input[type="submit"],
textarea {
  -webkit-appearance: none;
  -moz-appearance: none; }

input,
textarea,
select,
fieldset {
  border-radius: 2px;
  max-width: 100%; }
  input.input-full,
  textarea.input-full,
  select.input-full,
  fieldset.input-full {
    width: 100%; }

input,
select,
textarea {
  font-size: 1em;
  padding: 8px 10px;
  line-height: 1.42;
  background: transparent; }

.cart-row input[type="submit"],
.cart-row button[type="submit"] {
  margin-bottom: 2px; }

fieldset {
  border: 1px solid #dedede;
  padding: 15px 0; }

legend {
  border: 0;
  padding: 0; }

optgroup {
  font-weight: bold; }

input {
  display: inline-block;
  width: auto; }

button,
input[type="submit"] {
  cursor: pointer; }

/*================ Input width and border ================*/
input[type="text"],
input[type="search"],
input[type="password"],
input[type="email"],
input[type="file"],
input[type="number"],
input[type="tel"],
textarea,
select {
  border: 1px solid #dedede;
  width: 100%;
  max-width: 100%;
  display: block;
  margin: 0 0 20px; }
  input[type="text"]:focus,
  input[type="search"]:focus,
  input[type="password"]:focus,
  input[type="email"]:focus,
  input[type="file"]:focus,
  input[type="number"]:focus,
  input[type="tel"]:focus,
  textarea:focus,
  select:focus {
    border: 1px solid #c5c5c5; }
  input[type="text"][disabled], input[type="text"].disabled,
  input[type="search"][disabled],
  input[type="search"].disabled,
  input[type="password"][disabled],
  input[type="password"].disabled,
  input[type="email"][disabled],
  input[type="email"].disabled,
  input[type="file"][disabled],
  input[type="file"].disabled,
  input[type="number"][disabled],
  input[type="number"].disabled,
  input[type="tel"][disabled],
  input[type="tel"].disabled,
  textarea[disabled],
  textarea.disabled,
  select[disabled],
  select.disabled {
    cursor: default;
    background-color: #f6f6f6;
    border-color: #b6b6b6; }

@media screen and (min-width: 480px) {
  input[type="number"] {
    width: 50%; } }
textarea {
  min-height: 100px; }

input[type="checkbox"],
input[type="radio"] {
  display: inline;
  margin: 0;
  padding: 0; }

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-position: right center;
  background-image: url("//cdn.shopify.com/s/files/1/2383/2435/t/4/assets/arrow-down.svg?v=4122862565270221146");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-color: #eaeded;
  padding-right: 28px;
  text-indent: 0.01px;
  text-overflow: '';
  cursor: pointer;
  /*================ Hide the svg arrow in IE9 and below ================*/ }
  .ie9 select, .lt-ie9 select {
    padding-right: 10px;
    background-image: none; }

select::-ms-expand {
  display: none; }

/*================ Form labels ================*/
label,
legend {
  display: block;
  margin-bottom: 6px;
  font-size: 14px;
  text-transform: capitalize; }
  label.inline,
  legend.inline {
    display: inline; }
  .form-horizontal label.hidden-label, label.hidden-label, .form-horizontal
  legend.hidden-label,
  legend.hidden-label {
    height: 0;
    width: 0;
    margin-bottom: 0;
    overflow: hidden; }
    .ie9 .form-horizontal label.hidden-label, .lt-ie9 .form-horizontal label.hidden-label, .ie9 label.hidden-label, .lt-ie9 label.hidden-label, .ie9 .form-horizontal
    legend.hidden-label, .lt-ie9 .form-horizontal
    legend.hidden-label, .ie9
    legend.hidden-label, .lt-ie9
    legend.hidden-label {
      height: auto;
      margin-bottom: 2px;
      overflow: visible; }

/*================ We don't want the same label treatment for checkboxes/radios ================*/
input[type="checkbox"] + label,
input[type="radio"] + label {
  font-weight: normal; }

label[for] {
  cursor: pointer; }

.label-hint {
  color: #999; }

/*================ Horizontal Form ================*/
form.form-horizontal,
.form-horizontal {
  margin-bottom: 0; }
  form.form-horizontal input[type="text"],
  form.form-horizontal input[type="search"],
  form.form-horizontal input[type="password"],
  form.form-horizontal input[type="email"],
  form.form-horizontal input[type="file"],
  form.form-horizontal input[type="number"],
  form.form-horizontal input[type="tel"],
  form.form-horizontal textarea,
  form.form-horizontal select,
  form.form-horizontal label,
  .form-horizontal input[type="text"],
  .form-horizontal input[type="search"],
  .form-horizontal input[type="password"],
  .form-horizontal input[type="email"],
  .form-horizontal input[type="file"],
  .form-horizontal input[type="number"],
  .form-horizontal input[type="tel"],
  .form-horizontal textarea,
  .form-horizontal select,
  .form-horizontal label {
    display: inline-block;
    margin-bottom: 0;
    width: auto; }

/*================ Error styles ================*/
input[type="text"].error,
input[type="search"].error,
input[type="password"].error,
input[type="email"].error,
input[type="file"].error,
input[type="number"].error,
input[type="tel"].error,
textarea.error {
  border-color: #dc0000;
  background-color: #fff6f6;
  color: #dc0000; }

label.error {
  color: #dc0000; }

/*================ Input Group ================*/
.input-group {
  position: relative;
  display: table;
  border-collapse: separate; }
  .input-group .input-group-field:first-child,
  .input-group .input-group-btn:first-child,
  .input-group .input-group-btn:first-child > .btn,
  .input-group .input-group-btn:first-child > .btn-secondary,
  .input-group .input-group-btn:first-child > input[type="submit"],
  .input-group .input-group-btn:first-child > .header-cart-btn,
  .input-group input[type="hidden"]:first-child + .input-group-field,
  .input-group input[type="hidden"]:first-child + .input-group-btn > .btn,
  .input-group input[type="hidden"]:first-child + .input-group-btn > .btn-secondary,
  .input-group input[type="hidden"]:first-child + .input-group-btn > input[type="submit"],
  .input-group input[type="hidden"]:first-child + .input-group-btn > .header-cart-btn {
    border-radius: 2px 0 0 2px;
    border-right: none; }
  .input-group .input-group-field:last-child,
  .input-group .input-group-btn:last-child > .btn,
  .input-group .input-group-btn:last-child > .btn-secondary,
  .input-group .input-group-btn:last-child > input[type="submit"],
  .input-group .input-group-btn:last-child > .header-cart-btn {
    border-radius: 0 2px 2px 0; }
  .input-group input::-moz-focus-inner {
    border: 0;
    padding: 0;
    margin-top: -1px;
    margin-bottom: -1px; }

.input-group-field,
.input-group-btn {
  display: table-cell;
  vertical-align: middle;
  margin: 0; }

.input-group .btn, .input-group .btn-secondary, .input-group input[type="submit"], .input-group .header-cart-btn,
.input-group .input-group-field {
  height: 42px; }

.input-group .input-group-field {
  width: 100%;
  margin-bottom: 0;
  border-color: #ababab;
  border-right: none; }

.input-group-btn {
  position: relative;
  white-space: nowrap;
  width: 1%;
  padding: 0; }

.form-spacer {
  margin: 0 15px; }

/*============================================================================
  #Icons
==============================================================================*/
.icon-fallback-text .icon {
  display: none; }
  .supports-fontface .icon-fallback-text .icon {
    display: inline-block; }

/*============================================================================
  A generic way to visually hide content while
  remaining accessible to screen readers (h5bp.com)
==============================================================================*/
.supports-fontface .icon-fallback-text .fallback-text {
  clip: rect(0 0 0 0);
  overflow: hidden;
  position: absolute;
  height: 1px;
  width: 1px; }

.icon:before {
  display: none; }

.supports-fontface .icon:before {
  display: inline;
  font-family: "icons";
  text-decoration: none;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/*================ Icon mapping ================*/
.icon-amazon_payments:before {
  content: "\e90c"; }

.icon-american_express:before {
  content: "\41"; }

.icon-apple_pay:before {
  content: "\e90b"; }

.icon-arrow-down:before {
  content: "\34"; }

.icon-arrow-left:before {
  content: "\e900"; }

.icon-arrow-right:before {
  content: "\e901"; }

.icon-bitcoin:before {
  content: "\42"; }

.icon-cart:before {
  content: "\5b"; }

.icon-cirrus:before {
  content: "\43"; }

.icon-collection-view-grid:before {
  content: "\e902"; }

.icon-collection-view-list:before {
  content: "\e903"; }

.icon-dankort:before {
  content: "\64"; }

.icon-diners_club:before {
  content: "\63"; }

.icon-discover:before {
  content: "\44"; }

.icon-dogecoin:before {
  content: "\e905"; }

.icon-dwolla:before {
  content: "\e906"; }

.icon-facebook:before {
  content: "\66"; }

.icon-fancy:before {
  content: "\46"; }

.icon-forbrugsforeningen:before {
  content: "\e907"; }

.icon-google_plus:before {
  content: "\e90a"; }

.icon-hamburger:before {
  content: "\e904"; }

.icon-instagram:before {
  content: "\e908"; }

.icon-interac:before {
  content: "\49"; }

.icon-jcb:before {
  content: "\4a"; }

.icon-litecoin:before {
  content: "\e909"; }

.icon-maestro:before {
  content: "\6d"; }

.icon-master:before {
  content: "\4d"; }

.icon-paypal:before {
  content: "\50"; }

.icon-pin:before {
  content: "\e90e"; }

.icon-pinterest:before {
  content: "\70"; }

.icon-rss:before {
  content: "\72"; }

.icon-search:before {
  content: "\73"; }

.icon-snapchat:before {
  content: "\e90d"; }

.icon-stripe:before {
  content: "\53"; }

.icon-tumblr:before {
  content: "\74"; }

.icon-twitter:before {
  content: "\54"; }

.icon-vimeo:before {
  content: "\76"; }

.icon-visa:before {
  content: "\56"; }

.icon-x:before {
  content: "\78"; }

.icon-youtube:before {
  content: "\79"; }

/*================ Button icons ================*/
.shopify-payment-button__button {
  text-transform: uppercase; }

.shopify-payment-button__button--unbranded {
  background-color: #326ced; }

.icon-cart {
  line-height: 0; }
  .icon-cart:before {
    position: relative;
    top: -2px;
    font-size: 19px;
    line-height: 12px;
    vertical-align: middle;
    margin-right: 6px; }

.payment-icons {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  cursor: default;
  margin: 0; }
  .payment-icons li {
    margin: 0 7.5px 7.5px;
    color: #ffffff;
    cursor: default; }
  .payment-icons .icon {
    font-size: 30px;
    line-height: 30px; }
  .payment-icons .fallback-text {
    text-transform: capitalize; }

.social-icons li {
  margin: 0 10px 0 0;
  vertical-align: middle; }
  @media screen and (min-width: 481px) {
    .social-icons li {
      margin-left: 0; } }
  .social-icons li .icon {
    font-size: 20px;
    line-height: 16px; }
  .social-icons li a {
    color: #ffffff;
    -webkit-transition: color 0.2s ease-out;
    -moz-transition: color 0.2s ease-out;
    -ms-transition: color 0.2s ease-out;
    -o-transition: color 0.2s ease-out;
    transition: color 0.2s ease-out; }
    .social-icons li a:hover {
      color: #d9d9d9;
      -webkit-transition: color 0.05s ease-in;
      -moz-transition: color 0.05s ease-in;
      -ms-transition: color 0.05s ease-in;
      -o-transition: color 0.05s ease-in;
      transition: color 0.05s ease-in; }

ul.social-icons {
  margin-bottom: 0; }

/*================ Social share buttons ================*/
.social-sharing {
  font-family: "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding-top: 15px;
  /*================ Large Buttons ================*/ }
  .social-sharing a {
    display: inline-block;
    color: #fff;
    border-radius: 2px;
    margin: 0 10px 10px 0;
    height: 22px;
    line-height: 22px;
    text-decoration: none;
    font-weight: normal; }
    .social-sharing a:hover {
      color: #fff; }
  .social-sharing span {
    display: inline-block;
    vertical-align: top;
    height: 22px;
    line-height: 22px;
    font-size: 12px; }
  .social-sharing .icon {
    padding: 0 5px 0 10px; }
    .social-sharing .icon:before {
      line-height: 22px; }
  .social-sharing.is-large a {
    height: 44px;
    line-height: 44px; }
    .social-sharing.is-large a span {
      height: 44px;
      line-height: 44px;
      font-size: 18px; }
    .social-sharing.is-large a .icon {
      padding: 0 10px 0 18px; }
      .social-sharing.is-large a .icon:before {
        line-height: 44px; }

.share-title {
  font-weight: 900;
  font-size: 12px;
  padding-right: 10px; }
  .is-large .share-title {
    padding-right: 16px; }

.share-facebook {
  background-color: #3b5998; }
  .share-facebook:hover {
    background-color: #2d4373; }

.share-twitter {
  background-color: #1DA1F2; }
  .share-twitter:hover {
    background-color: #0c85d0; }

.share-pinterest {
  background-color: #c8232c; }
  .share-pinterest:hover {
    background-color: #9d1b22; }

.share-fancy {
  background-color: #4999dc; }
  .share-fancy:hover {
    background-color: #2780cb; }

.share-google {
  background-color: #dd4b39; }
  .share-google:hover {
    background-color: #c23321; }

/*================ Clean Buttons ================*/
.social-sharing.is-clean a {
  background-color: #fff;
  border: 1px solid #ececec;
  color: #333;
  height: 30px;
  line-height: 30px; }
  .social-sharing.is-clean a span {
    height: 30px;
    line-height: 30px;
    font-size: 13px; }
  .social-sharing.is-clean a:hover {
    background-color: #f9f9f9; }
  .social-sharing.is-clean a .share-title {
    font-weight: normal; }
.social-sharing.is-clean .icon-facebook {
  color: #3b5998; }
.social-sharing.is-clean .icon-twitter {
  color: #00aced; }
.social-sharing.is-clean .icon-pinterest {
  color: #cb2027; }

/*============================================================================
  #Pagination
==============================================================================*/
.pagination-border-top {
  border-top: 1px solid #dedede; }

/*================ Custom Pagination ================*/
.pagination-custom {
  display: inline-block;
  padding-left: 0;
  margin: 0 0 30px; }

.pagination-custom > li {
  display: inline; }

.pagination-custom > li > a,
.pagination-custom > li > span {
  position: relative;
  float: left;
  padding: 5px 10px;
  margin-left: -1px;
  line-height: 1.42;
  text-decoration: none;
  border: 1px solid #ababab; }

.pagination-custom > li:first-child > a,
.pagination-custom > li:first-child > span {
  margin-left: 0; }

.pagination-custom > .active > a,
.pagination-custom > .active > span,
.pagination-custom > .active > a:hover,
.pagination-custom > .active > span:hover,
.pagination-custom > .active > a:focus,
.pagination-custom > .active > span:focus {
  z-index: 2;
  cursor: default;
  color: black; }

.pagination-custom > .disabled > span,
.pagination-custom > .disabled > a,
.pagination-custom > .disabled > a:hover,
.pagination-custom > .disabled > a:focus {
  color: #999999;
  cursor: not-allowed; }

.pagination-custom-lg > li > a,
.pagination-custom-lg > li > span {
  padding: 10px 16px;
  font-size: 18px; }

.pagination-custom-sm > li > a,
.pagination-custom-sm > li > span {
  padding: 5px 10px;
  font-size: 0.75em; }

/*============================================================================
  #Site Header
==============================================================================*/
/*============================================================================
		FLOATING NAV
#shopify-section-header {
    position: fixed; 
  top: 0; 
    width: 100%;
    z-index: 1;
}
==============================================================================*/
.site-header {
  padding: 15px 0;
  background-color: white;
  color: black; }
  @media screen and (min-width: 769px) {
    .site-header {
      padding: 30px 0; } }
  .site-header .site-header--text-links a {
    color: black; }
    .site-header .site-header--text-links a:hover {
      opacity: 0.9; }
  @media screen and (min-width: 769px) {
    .site-header .grid, .site-header .grid--rev, .site-header .grid--full {
      display: table;
      table-layout: fixed;
      width: 100%; }
      .site-header .grid > .grid-item, .site-header .grid--rev > .grid-item, .site-header .grid--full > .grid-item {
        float: none;
        display: table-cell;
        vertical-align: middle; } }

.headerbg {
  padding: 10px; }

/*============================================================================
.headerbg{
  background-image: url("https://cdn.shopify.com/s/files/1/2383/2435/files/baseball-discount-pet-deals-bg.jpg");
  background-size: cover;
  background-color: #ffffff;
}
.headerbgcolor{
  background-color: #ffffff;
  padding:10px;
}
==============================================================================*/
.header-logo {
  margin: 30px auto; }
  @media screen and (max-width: 768px) {
    .header-logo {
      margin: 0 auto; }
      .header-logo .lazyload__image-wrapper {
        margin: 0 auto;
        width: 110px;
        float: left;
        vertical-align: middle; } }
  @media screen and (max-width: 480px) {
    .header-logo {
      margin: 0 auto; }
      .header-logo .lazyload__image-wrapper {
        margin: 0 auto;
        width: 80px;
        float: left;
        vertical-align: middle; } }
  @media screen and (min-width: 769px) {
    .header-logo {
      margin: 0 auto; } }
  .header-logo a,
  .header-logo a:hover,
  .header-logo a:focus {
    text-decoration: none;
    color: black; }
  .header-logo a, .header-logo img {
    display: block; }
  .header-logo img {
    margin: 0 auto; }
    @media screen and (min-width: 769px) {
      .header-logo img {
        float: left; } }

@media screen and (max-width: 768px) {
  .mobilewrapper {
    width: 460px;
    margin: 0 auto; } }
@media screen and (max-width: 480px) {
  .mobilewrapper {
    width: 360px;
    margin: 0 auto; } }

@media screen and (max-width: 768px) {
  .announcementlogo {
    width: 200px;
    float: left;
    margin: 0 auto; } }
@media screen and (max-width: 480px) {
  .announcementlogo {
    width: 150px;
    float: left;
    margin: 0 auto; } }

@media screen and (max-width: 768px) {
  .announcementdiv {
    width: 240px;
    margin: -45px 0 0 0;
    float: right; } }
@media screen and (max-width: 480px) {
  .announcementdiv {
    width: 200px;
    margin: -35px 0 0 0;
    float: right; } }

.site-header--text-links {
  display: inline-block;
  font-size: 0.85em;
  margin-bottom: 15px; }
  .site-header--text-links a {
    font-weight: bold; }
  @media screen and (max-width: 768px) {
    .site-header--text-links .announcementtxt {
      text-align: right;
      font-size: 1.25em;
      margin: 0 auto; } }
  @media screen and (max-width: 480px) {
    .site-header--text-links .announcementtxt {
      text-align: right;
      font-size: 1.05em;
      margin: 0 auto; } }

.site-header--meta-links {
  display: inline-block;
  margin-left: 15px; }

.site-header--spacer {
  padding: 0 0px; }

.header-cart-btn {
  position: relative;
  line-height: 26px;
  padding-bottom: 10px;
  border: 0 none;
  margin-left: 15px;
  vertical-align: top;
  background-color: #f3f3f3;
  color: black; }
  @media screen and (max-width: 768px) {
    .header-cart-btn {
      display: none; } }
  .header-cart-btn:hover, .header-cart-btn:focus {
    color: black;
    background-color: #e6e6e6; }

.cart-count {
  display: inline-block;
  text-align: center;
  background-color: #ff9900;
  color: white;
  font-weight: bold;
  min-width: 20px;
  height: 20px;
  line-height: 20px;
  margin: 8px 0;
  border-radius: 2px; }
  .cart-count.hidden-count {
    display: none; }

.cart-badge--desktop {
  position: absolute;
  top: -5px;
  right: -5px; }

.search-bar {
  position: relative;
  max-width: 60%;
  width: 100%;
  margin: 0 auto 30px; }
  .search-bar .icon-search {
    font-size: 18px;
    color: #000000; }
  .search-bar .mobile-search-area {
    margin-top: 5px; }
  .search-bar .mobile-icon-search {
    color: #FFFFFF; }
  @media screen and (max-width: 768px) {
    .search-bar {
      display: block;
      max-width: 100%;
      margin-bottom: 15px; } }
  .search-bar input[type="text"],
  .search-bar input[type="search"] {
    width: 100%;
    margin-bottom: 0;
    font-family: "Roboto", "HelveticaNeue", "Helvetica Neue", sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: normal; }
  .search-bar input:-ms-input-placeholder {
    line-height: normal;
    display: inline-block; }
  .search-bar input[type="submit"],
  .search-bar .search-bar--submit {
    position: absolute;
    top: 2px;
    bottom: 0;
    right: 0;
    width: 45px;
    margin: 0;
    border: 0 none;
    background: none;
    text-align: center;
    cursor: pointer; }
    .site-header .search-bar input[type="submit"], .site-header
    .search-bar .search-bar--submit {
      color: black; }
  .search-bar input[type="submit"] {
    text-indent: -9999px; }
  .site-header .search-bar {
    display: none;
    margin-bottom: 0; }
    @media screen and (min-width: 769px) {
      .site-header .search-bar {
        display: inline-block; } }
    .site-header .search-bar input[type="text"],
    .site-header .search-bar input[type="search"] {
      height: 44px;
      border: 0 none;
      background-color: #f3f3f3;
      color: black;
      -webkit-transition: background-color 0.2s ease-out;
      -moz-transition: background-color 0.2s ease-out;
      -ms-transition: background-color 0.2s ease-out;
      -o-transition: background-color 0.2s ease-out;
      transition: background-color 0.2s ease-out; }
      .site-header .search-bar input[type="text"]:hover, .site-header .search-bar input[type="text"]:focus,
      .site-header .search-bar input[type="search"]:hover,
      .site-header .search-bar input[type="search"]:focus {
        background-color: #e6e6e6;
        -webkit-transition: background-color 0.05s ease-in;
        -moz-transition: background-color 0.05s ease-in;
        -ms-transition: background-color 0.05s ease-in;
        -o-transition: background-color 0.05s ease-in;
        transition: background-color 0.05s ease-in; }
      .site-header .search-bar input[type="text"]:focus,
      .site-header .search-bar input[type="search"]:focus {
        outline: 0; }

.mobile-search-bar {
  margin-top: 10px;
  font-color: #ffffff;
  color: FFFFFF; }

.site-header input::-webkit-input-placeholder {
  color: black; }

.site-header input::-moz-placeholder {
  color: black; }

/*============================================================================
  #Site Nav and Dropdowns
==============================================================================*/
.nav-bar {
  top: 110%;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #004f00; }
  .nav-bar a {
    color: white; }
    .nav-bar a:active, .nav-bar a:focus {
      opacity: 0.9; }

.site-nav {
  text-align: left;
  margin: 0;
  font-family: "Roboto", "HelveticaNeue", "Helvetica Neue", sans-serif;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  cursor: default; }
  .site-nav li {
    margin: 0;
    display: block; }
  .site-nav a {
    display: block;
    text-decoration: none;
    padding: 15px; }
  .site-nav .customer-navlink {
    color: white;
    background-color: #006900;
    border-bottom: 0;
    border-top: 1px solid #003600;
    margin-top: -1px; }

/*================ Small screen nav styles ================*/
@media screen and (max-width: 480px) {
  .show-nav .nav-bar {
    bottom: 0;
    top: 50px; }

  .show-nav,
  .show-nav body {
    height: 100%;
    overflow: hidden; } }
/*================ Medium-down screen nav styles ================*/
@media screen and (max-width: 768px) {
  .nav-bar {
    position: fixed;
    z-index: 2000;
    -webkit-transition: all 600ms cubic-bezier(0.47, 0.5, 0, 0.95);
    -moz-transition: all 600ms cubic-bezier(0.47, 0.5, 0, 0.95);
    -ms-transition: all 600ms cubic-bezier(0.47, 0.5, 0, 0.95);
    -o-transition: all 600ms cubic-bezier(0.47, 0.5, 0, 0.95);
    transition: all 600ms cubic-bezier(0.47, 0.5, 0, 0.95); }
    .show-nav .nav-bar {
      max-height: 100%;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      -webkit-transition: all 450ms cubic-bezier(0.47, 0, 0, 0.95);
      -moz-transition: all 450ms cubic-bezier(0.47, 0, 0, 0.95);
      -ms-transition: all 450ms cubic-bezier(0.47, 0, 0, 0.95);
      -o-transition: all 450ms cubic-bezier(0.47, 0, 0, 0.95);
      transition: all 450ms cubic-bezier(0.47, 0, 0, 0.95); }
    .nav-bar .wrapper {
      padding: 15px 0 0; }
      .show-nav .nav-bar .wrapper {
        min-height: 101%; }
    .nav-bar .search-bar {
      margin: 0 15px 15px;
      width: auto; }
      .nav-bar .search-bar input[type="search"]::-webkit-input-placeholder {
        color: white; }
      .nav-bar .search-bar input::-webkit-input-placeholder {
        color: white; }
      .nav-bar .search-bar .search-bar--submit .icon {
        color: white; }

  .site-nav {
    border-top: 1px solid #008200; }

  /*================ Menu items ================*/
  .site-nav li {
    border-bottom: 1px solid #00b500;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0.2); } }
/*================ Medium screen size only ================*/
@media screen and (min-width: 481px) and (max-width: 768px) {
  .show-nav .nav-bar {
    top: 50px;
    bottom: 0; } }
/*================ Large screen bar navigation ================*/
@media screen and (min-width: 769px) {
  .nav-bar .search-bar {
    display: none; }

  .site-nav {
    text-align: left;
    white-space: nowrap;
    margin-left: -15px; }
    .site-nav > li {
      position: relative;
      display: inline-block; }
    .site-nav a {
      white-space: nowrap; } }
/*================ Dropdowns ================*/
.icon-arrow-down {
  position: relative;
  top: -2px;
  font-size: 12px; }

.site-nav--dropdown {
  display: none;
  margin: 0; }

.site-nav--dropdown .icon-arrow-down {
  display: none !important; }

/*================ Large screen dropdown styles ================*/
@media screen and (min-width: 769px) {
  .site-nav--dropdown {
    display: block;
    position: absolute;
    color: yellow;
    top: 100%;
    left: 0;
    border: 1px solid #dedede;
    background-color: #eaeded;
    opacity: 0;
    overflow: hidden;
    visibility: hidden;
    z-index: 20;
    -webkit-transition: all 150ms;
    -moz-transition: all 150ms;
    -ms-transition: all 150ms;
    -o-transition: all 150ms;
    transition: all 150ms; }
    .site-nav--dropdown > li {
      height: 25px;
      margin-bottom: 2px; }
    .no-touch .site-nav--has-dropdown:hover .site-nav--dropdown, .site-nav--has-dropdown.nav-hover .site-nav--dropdown, .nav-focus + .site-nav--dropdown, .show-dropdown .site-nav--dropdown {
      opacity: 1;
      box-shadow: 0 5px 40px rgba(0, 0, 0, 0.05);
      visibility: visible;
      -webkit-transition: all 90ms;
      -moz-transition: all 90ms;
      -ms-transition: all 90ms;
      -o-transition: all 90ms;
      transition: all 90ms; }
    .site-nav--dropdown a {
      color: #008200;
      text-transform: none;
      padding: 10px 45px 10px 15px; }
      .site-nav--dropdown a:hover, .site-nav--dropdown a:active, .site-nav--dropdown a:focus {
        color: #004f00; }
      .site-nav--dropdown a:focus {
        background-color: #dce1e1; } }
/*================ Medium down dropdown styles ================*/
@media screen and (max-width: 768px) {
  .site-nav {
    text-align: left; }

  .site-nav--dropdown {
    background-color: #ffffff; }
    .site-nav--dropdown > li {
      float: none;
      width: 100%;
      border: none;
      text-transform: none;
      border-bottom: 1px solid #006900; }

  .show-dropdown .site-nav--dropdown {
    display: block; } }
/*============================================================================
  #Mobile Nav Bar
==============================================================================*/
#mobileNavBar {
  display: none;
  width: 100%;
  background-color: #004f00;
  /*================ Small mobile nav bar ================*/
  /*================ Medium-down mobile nav bar ================*/ }
  #mobileNavBar:after {
    content: "";
    display: table;
    clear: both; }
  #mobileNavBar .display-table-cell {
    padding: 0 5px 0 5px; }
  #mobileNavBar .cart-toggle {
    text-align: right; }
  @media screen and (max-width: 480px) {
    #mobileNavBar {
      position: fixed;
      top: 0;
      left: 0;
      right: 0; } }
  @media screen and (max-width: 768px) {
    #mobileNavBar {
      display: table;
      height: 50px;
      z-index: 999999999; }
      .show-nav #mobileNavBar {
        position: fixed;
        left: 0;
        top: 0; } }

.mobileNavBar-link {
  display: block;
  padding: 10px 0;
  color: white;
  cursor: pointer; }
  .mobileNavBar-link:hover, .mobileNavBar-link:focus {
    color: white; }
  .mobileNavBar-link.menu-toggle .icon {
    font-size: 12px;
    margin-right: 10px; }
  .mobileNavBar-link.menu-toggle .lt-ie9 {
    background-image: none;
    padding-left: 0; }

@media screen and (max-width: 768px) {
  :target {
    padding-top: 50px; } }
/*============================================================================
  #Site Footer
==============================================================================*/
.site-footer {
  background-color: #004f00;
  padding: 30px 0 10px;
  color: white; }
  @media screen and (min-width: 769px) {
    .site-footer {
      padding: 60px 0 6px; } }
  .site-footer h3, .site-footer .h3 {
    margin: 0 0 15px 0; }
  .site-footer a {
    color: white; }
    .site-footer a:hover {
      color: #cccccc; }

.legal-links {
  margin-bottom: 0;
  line-height: 2em; }
  @media screen and (max-width: 768px) {
    .legal-links {
      margin-bottom: 15px; } }
  .legal-links + .legal-links {
    margin-bottom: 15px; }
  .legal-links li {
    padding: 0 15px 0 0;
    font-size: 13px; }

/*============================================================================
  #Product Grid and List Views
==============================================================================*/
.row-negative-bottom {
  margin-bottom: -30px; }

.sold-out .product-grid-image,
.sold-out .product-list-image {
  position: relative; }
  .sold-out .product-grid-image img,
  .sold-out .product-list-image img {
    opacity: 0.7; }

.badge {
  display: none;
  position: absolute;
  top: 50%;
  width: 100%;
  margin-top: -15px;
  text-align: center;
  z-index: 5; }

.badge-label {
  display: inline-block;
  margin: 0 auto;
  padding: 5px;
  font-size: 13px;
  font-weight: bold;
  color: #eaeded;
  background-color: black;
  text-transform: uppercase;
  border-radius: 2px; }

.sold-out .badge--sold-out {
  display: block; }

@media screen and (max-width: 768px) {
  .product-list-item .spr-badge {
    clear: both; } }
@media screen and (min-width: 769px) {
  .product-list-item .grid, .product-list-item .grid--rev, .product-list-item .grid--full {
    display: table;
    table-layout: fixed;
    width: 100%; }
  .product-list-item .grid-item {
    display: table-cell; } }
.product-grid-image {
  display: table;
  table-layout: fixed;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  margin: 0 auto 15px; }
  .product-grid-image img {
    display: block;
    margin: 0 auto;
    -webkit-transition: opacity 0.3s ease-out;
    -moz-transition: opacity 0.3s ease-out;
    -ms-transition: opacity 0.3s ease-out;
    -o-transition: opacity 0.3s ease-out;
    transition: opacity 0.3s ease-out;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden; }

.product-list-image {
  display: block;
  margin: 0 auto; }

.product-grid-image--centered {
  display: table-cell;
  vertical-align: middle; }

a.product-grid-item,
.product-grid-item {
  display: block;
  color: black;
  margin-bottom: 60px; }
  a.product-grid-item:hover img,
  .product-grid-item:hover img {
    opacity: 0.9;
    -webkit-transition: opacity 0.05s ease-out;
    -moz-transition: opacity 0.05s ease-out;
    -ms-transition: opacity 0.05s ease-out;
    -o-transition: opacity 0.05s ease-out;
    transition: opacity 0.05s ease-out; }
  @media screen and (min-width: 769px) {
    a.product-grid-item,
    .product-grid-item {
      margin-bottom: 30px; }
      .template-list-collections a.product-grid-item, .template-list-collections
      .product-grid-item {
        margin-bottom: 0; } }

.product-item--price:after {
  content: "";
  display: table;
  clear: both; }
.product-item--price .h1 {
  margin-bottom: 15px; }
.product-item--price span {
  line-height: 22px; }
.product-item--price small {
  white-space: nowrap; }

.sale-tag {
  display: inline-block;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 900;
  padding: 0 8px;
  border-radius: 3px;
  vertical-align: text-bottom;
  color: #008200;
  border: 2px solid #008200; }
  .sale-tag sup {
    display: none; }
  .sale-tag.large {
    font-size: 16px;
    padding: 0 12px; }
  .product-meta .sale-tag {
    position: relative;
    top: 2px; }
  .sale-tag.has-reviews {
    margin-bottom: 10px; }

.featured-box {
  position: relative;
  display: block;
  padding: 5px 0;
  border: 1px solid #dedede;
  margin-bottom: 10px; }
  .featured-box:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.02);
    z-index: 5; }
  .featured-box img, .featured-box svg {
    -webkit-transition: opacity 0.1s ease-in-out;
    -moz-transition: opacity 0.1s ease-in-out;
    -ms-transition: opacity 0.1s ease-in-out;
    -o-transition: opacity 0.1s ease-in-out;
    transition: opacity 0.1s ease-in-out;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden; }
  .featured-box:hover img {
    opacity: 0.9; }

.featured-box--inner {
  display: block;
  margin: 0 auto; }

.featured-box--image {
  display: block;
  vertical-align: middle;
  width: 100%;
  padding: 15px; }
  .featured-box--image img, .featured-box--image svg {
    width: 100%; }
    .featured-box--image img.placeholder-svg, .featured-box--image svg.placeholder-svg {
      max-width: 100%;
      max-height: 100%;
      margin: 0 auto; }

.featured-box--title {
  position: relative;
  display: block;
  color: black;
  font-family: "Roboto", "HelveticaNeue", "Helvetica Neue", sans-serif;
  font-weight: 500;
  line-height: 1;
  z-index: 10; }

/*============================================================================
  #Collection Filters
==============================================================================*/
@media screen and (max-width: 768px) {
  .collection-filters {
    display: none; } }
@media screen and (min-width: 769px) {
  .toggle-filters {
    display: none; } }
.advanced-filters {
  margin-bottom: 30px; }

.advanced-filters a {
  display: block;
  position: relative;
  padding-left: 20px; }
  .advanced-filters a:before, .advanced-filters a:after {
    content: '';
    display: block;
    position: absolute; }
  .advanced-filters a:before {
    top: 6px;
    left: 0;
    width: 12px;
    height: 12px;
    background-color: #eaeded;
    border: 1px solid #dedede; }
  .advanced-filters a:after {
    top: 9px;
    left: 3px;
    width: 6px;
    height: 6px;
    background-color: transparent; }
  .advanced-filters a:hover:after {
    background-color: #dedede; }

.advanced-filter.active-filter a:after {
  background-color: #000; }
.advanced-filter.active-filter a:hover:after {
  content: '\78';
  top: 6px;
  left: 0;
  width: 12px;
  height: 12px;
  background-color: #000;
  font-size: 8px;
  text-align: center;
  color: #fff;
  font-family: "icons";
  line-height: 12px;
  speak: none; }

/*============================================================================
  #Breadcrumbs
==============================================================================*/
.breadcrumb {
  font-size: 12px;
  border-bottom: 1px solid #dedede;
  margin-bottom: 10px;
  margin-top: -30px;
  height: 40px; }
  .lt-ie9 .breadcrumb {
    margin-top: 0; }
  @media screen and (min-width: 769px) {
    .template-page .breadcrumb, .template-product .breadcrumb {
      margin-bottom: 60px; } }
  @media screen and (max-width: 768px) {
    .breadcrumb {
      height: auto; } }
  .breadcrumb a {
    color: black; }
    .breadcrumb a:hover {
      color: black; }
  .breadcrumb a,
  .breadcrumb span {
    display: inline-block;
    padding: 15px 7px 15px 0;
    margin-right: 7px;
    overflow: hidden; }
    .breadcrumb a:first-child,
    .breadcrumb span:first-child {
      padding-left: 0; }
  .breadcrumb .divider {
    font-size: 15px;
    font-family: 'Verdana', sans-serif;
    padding: 13px 7px 13px 0;
    color: #767676; }
  .breadcrumb + .grid-border {
    margin-top: -30px; }

.breadcrumb--truncate {
  width: 250px;
  white-space: nowrap;
  text-overflow: ellipsis; }

@media screen and (max-width: 480px) {
  .breadcrumb {
    padding: 10px 0; }

  .main-content {
    padding-top: 0px; }

  .logohide {
    display: none; } }
/*============================================================================
  #Product Page
==============================================================================*/
/*================ Select fields ================*/
.product-variants {
  display: none; }
  .no-js .product-variants {
    display: block; }

@media screen and (min-width: 769px) {
  .selector-wrapper select,
  .product-variants select {
    max-width: 40%; } }
.add-to-cart--large {
  padding: 16px 20px;
  font-weight: 700;
  margin-bottom: 0px; }

#addToCartForm {
  margin-bottom: 0; }

@media screen and (min-width: 481px) {
  #addToCartForm select {
    max-width: 50%; } }
/*================ Product details ================*/
.write-review-btn-hidden {
  display: none; }

.price {
  font-size: 1.750em; }

#productPrice {
  font-size: 32px;
  margin-bottom: 0;
  white-space: nowrap; }

#comparePrice {
  white-space: nowrap; }

.product-meta {
  margin-bottom: 5px; }
  .product-meta .product-meta--review {
    display: block; }
  .product-meta span {
    margin-bottom: 0; }
  @media screen and (min-width: 481px) {
    .product-meta {
      display: table; }
      .product-meta li,
      .product-meta .product-meta--review {
        display: table-cell;
        vertical-align: middle;
        padding-right: 15px; } }

.full-details {
  display: inline-block;
  margin-top: 15px;
  cursor: pointer; }

/*================ Notice of < 10 items left ================*/
.variant-quantity {
  display: none;
  color: #008200;
  font-weight: bold;
  padding: 0px; }
  .variant-quantity.is-visible {
    display: inline-block; }

/*================ Product photos ================*/
.product-photo-container {
  margin: 0 0 0 0;
  max-width: 100%; }

.product-photo-container a, .product-photo-container img,
.product-photo-thumbs a,
.product-photo-thumbs img {
  display: block;
  margin: 0 auto; }
.product-photo-container li,
.product-photo-thumbs li {
  margin-bottom: 30px; }

@media screen and (min-width: 769px) {
  .image-zoom {
    display: inline-block;
    cursor: move; } }
/*============================================================================
  #Blogs and Comments
==============================================================================*/
.comment {
  margin-bottom: 30px; }
  .comment + .comment {
    border-top: 1px solid #dedede;
    padding-top: 30px; }

/*============================================================================
  #Notes and Form Feedback
==============================================================================*/
.note,
.errors {
  border-radius: 2px;
  padding: 15px;
  margin-bottom: 15px;
  border: 1px solid transparent; }
  .note ul,
  .note ol,
  .errors ul,
  .errors ol {
    margin-top: 0;
    margin-bottom: 0; }
  .note li:last-child,
  .errors li:last-child {
    margin-bottom: 0; }
  .note p,
  .errors p {
    margin-bottom: 0; }

.note {
  border-color: #dedede; }

.errors ul {
  list-style: disc outside;
  margin-left: 20px; }

.form-success {
  color: #0a942a;
  background-color: #ecfef0;
  border-color: #0a942a;
  padding: 0.5em; }

.form-error,
.errors {
  color: #dc0000;
  background-color: #fff6f6;
  border-color: #dc0000;
  padding: 0.5em; }

/*============================================================================
  #Cart Page
==============================================================================*/
.cart-row {
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid #dedede; }
  .cart-row:first-child {
    margin-top: 0; }
  .cart-row:first-child {
    padding-top: 0;
    border-top: 0 none; }
  .cart-row a {
    color: black; }
    .cart-row a:hover {
      color: #008200; }
  .cart-row input[type="image"] {
    padding: 15px 0px; }
  .cart-row .additional-checkout-buttons > *:not(script) {
    padding: 10px 0 0 10px;
    vertical-align: top;
    line-height: 1; }
    .cart-row .additional-checkout-buttons > *:not(script):first-child, .cart-row .additional-checkout-buttons > *:not(script):empty {
      padding-left: 0px; }

.cart-image,
.cart-image img {
  display: block;
  width: 100%;
  max-height: inherit;
  height: inherit; }

@media screen and (max-width: 768px) {
  .cart-image {
    margin-bottom: 15px; } }
.cart-subtotal--price {
  margin: 0 0 0 10px;
  display: inline; }

.cart-subtotal--savings,
.cart-item--discount {
  display: block;
  padding-bottom: 15px;
  font-weight: 700; }

.cart--no-cookies .cart--empty-message {
  display: none; }

.cart--no-cookies .cart--continue-message {
  display: none; }

.cart--cookie-message {
  display: none;
  padding-bottom: 25px; }
  .cart--no-cookies .cart--cookie-message {
    display: block; }

/*================ Remove from cart icon ================*/
a.remove {
  display: inline-block;
  margin-top: 7px;
  height: 19px;
  line-height: 19px;
  padding: 0 5px;
  border-radius: 19px;
  font-size: 9px;
  text-align: center;
  border: 0 none; }
  .supports-fontface a.remove {
    padding: 0;
    width: 19px; }

/*================ Ajax Cart ================*/
#ajaxifyDrawer .ajaxifyCart--content a.remove {
  color: black; }

@media screen and (max-width: 480px) {
  body {
    padding-top: 50px; }

  .ajaxify-modal .ajaxifyCart--close {
    top: 67px; }
  .ajaxify-modal .ajaxifyCart--content {
    margin-top: 50px;
    margin-bottom: -50px; } }
/*============================================================================
  #Demo Styles - for empty store state
==============================================================================*/
.demo-image {
  background: url("//cdn.shopify.com/s/files/1/0383/9765/t/1/assets/blankslate-producticon.png?4") no-repeat center center #eeeeee;
  display: block;
  text-align: center;
  padding: 100px 0;
  color: #aaa;
  font-size: 0.875em;
  text-decoration: none; }

/*============================================================================
  #FlexSlider
    - jQuery FlexSlider v2.2.2 | http://www.woothemes.com/flexslider/
    - Contributing author: Tyler Smith (@mbmufffin)
==============================================================================*/
.flexslider {
  margin: 0 0 45px;
  padding: 0; }

.flexslider li {
  margin: 0;
  max-width: 100%; }

.flexslider .slides > li {
  display: none;
  /* Hide the slides before the JS is loaded. Avoids image jumping */
  margin: 0;
  position: relative;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden; }
  .flexslider .slides > li .slide-hide {
    visibility: hidden; }

.flexslider .slides img {
  max-width: 100%;
  margin: 0 auto;
  display: block; }

.flexslider .slides .placeholder-noblocks {
  height: auto;
  width: 100%;
  max-height: 100%; }
  @media screen and (min-width: 769px) {
    .flexslider .slides .placeholder-noblocks {
      height: 430px; } }
  @media screen and (max-width: 768px) {
    .flexslider .slides .placeholder-noblocks {
      height: 30vh; } }

.slides:after {
  content: "";
  display: table;
  clear: both; }

html[xmlns] .slides {
  display: block; }

* html .slides {
  height: 1%; }

/*================ No JS Fallback ================*/
.no-js .slides > li:first-child {
  display: block; }

.flexslider {
  position: relative;
  zoom: 1; }

.flex-viewport {
  max-height: 2000px;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease; }

.loading .flex-viewport {
  max-height: 300px; }

.flexslider .slides {
  zoom: 1; }

.carousel li {
  margin-right: 5px; }

/*================ Direction Nav ================*/
.flex-direction-nav {
  margin: 0;
  padding: 0;
  list-style: none; }

.flex-direction-nav {
  *height: 0; }

.flex-direction-nav a {
  display: block;
  width: 45px;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 10;
  overflow: hidden;
  opacity: 0;
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease; }

.flex-direction-nav .flex-disabled {
  opacity: 0 !important;
  filter: alpha(opacity=0);
  cursor: default; }

.flex-direction-nav a {
  text-indent: -9999px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: 20px auto;
  /*================ Hide SVG arrows in oldIE ================*/ }
  .lte-ie9 .flex-direction-nav a {
    display: none; }
  .flex-direction-nav a.flex-prev {
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMjIuM3B4IiBoZWlnaHQ9IjQwcHgiIHZpZXdCb3g9IjAgMCAyMi4zIDQwIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAyMi4zIDQwIiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9IiNEM0QzRDMiIGQ9Ik0xOC43LDBMMCwxOS43TDE4LjcsNDBjMCwwLDUuMi0xLDMuMS0zLjFTNS43LDE5LjcsNS43LDE5LjdzMTQtMTQuNSwxNi4xLTE2LjZTMTguNywwLDE4LjcsMHoiLz4NCjwvc3ZnPg0K");
    background-position: center left; }
  .flex-direction-nav a.flex-next {
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMjIuM3B4IiBoZWlnaHQ9IjQwcHgiIHZpZXdCb3g9IjAgMCAyMi4zIDQwIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAyMi4zIDQwIiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9IiNEM0QzRDMiIGQ9Ik0wLjUsMy4xYzIuMSwyLjEsMTYuMSwxNi42LDE2LjEsMTYuNlMyLjYsMzQuOCwwLjUsMzYuOVMzLjYsNDAsMy42LDQwbDE4LjctMjAuM0wzLjYsMEMzLjYsMC0xLjYsMSwwLjUsMy4xDQoJeiIvPg0KPC9zdmc+DQo=");
    background-position: center right; }

/*================ Control Nav ================*/
.flex-control-nav {
  position: absolute;
  bottom: -30px;
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 0;
  list-style: none; }
  .flex-control-nav li {
    margin: 0 4px;
    display: inline-block;
    zoom: 1;
    *display: inline;
    vertical-align: middle; }

.flex-control-paging li a {
  width: 12px;
  height: 12px;
  display: block;
  background-color: #dedede;
  cursor: pointer;
  text-indent: -9999px;
  border-radius: 20px;
  border: 2px solid #eaeded;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out; }
  .flex-control-paging li a:hover {
    background-color: #c5c5c5; }
  .flex-control-paging li a.flex-active {
    background-color: #eaeded;
    border-color: #008200;
    cursor: default; }

.flex-control-thumbs {
  margin: 5px 0 0;
  position: static;
  overflow: hidden; }

.flex-control-thumbs li {
  width: 25%;
  float: left;
  margin: 0; }

.flex-control-thumbs img {
  width: 100%;
  display: block;
  opacity: .7;
  cursor: pointer; }

.flex-control-thumbs img:hover {
  opacity: 1; }

.flex-control-thumbs .flex-active {
  opacity: 1;
  cursor: default; }

@media screen and (max-width: 768px) {
  .flex-direction-nav a {
    opacity: 1;
    width: 25px; }

  .flex-direction-nav a.flex-prev {
    left: 0;
    background-position: center right; }

  .flex-direction-nav a.flex-next {
    right: 0;
    background-position: center left; } }
@media screen and (min-width: 769px) {
  .flex-direction-nav .flex-prev {
    left: 10px; }

  .flex-direction-nav .flex-next {
    right: 10px; }

  .flexslider:hover .flex-prev {
    opacity: 1;
    left: -25px; }

  .flexslider:hover .flex-next {
    opacity: 1;
    right: -25px; } }
@media screen and (min-width: 1120px) {
  .flex-direction-nav .flex-prev {
    left: 20px; }

  .flex-direction-nav .flex-next {
    right: 20px; }

  .flexslider:hover .flex-prev {
    opacity: 1;
    left: -45px; }

  .flexslider:hover .flex-next {
    opacity: 1;
    right: -45px; } }
/*================ Custom Flexslider Styles ================*/
.flexslider .slides {
  margin: 0;
  padding: 0;
  list-style-type: none; }

.slide-link {
  display: block; }
  .slide-link img {
    display: block; }

/*============================================================================
  #Magnific Popup
    http://dimsemenov.com/plugins/magnific-popup/
==============================================================================*/
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
  filter: alpha(opacity=80); }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.mfp-inline-holder .mfp-content, .mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close, .mfp-arrow, .mfp-preloader, .mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }

.mfp-preloader a {
  color: #CCC; }

.mfp-preloader a:hover {
  color: #FFF; }

.mfp-s-ready .mfp-preloader {
  display: none; }

.mfp-s-error .mfp-content {
  display: none; }

button.mfp-close, button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  -webkit-box-shadow: none;
  box-shadow: none; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  filter: alpha(opacity=65);
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace; }

.mfp-close:hover, .mfp-close:focus {
  opacity: 1;
  filter: alpha(opacity=100); }

.mfp-close:active {
  top: 1px; }

.mfp-close-btn-in .mfp-close {
  color: #333; }

.mfp-image-holder .mfp-close, .mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  filter: alpha(opacity=65);
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.mfp-arrow:active {
  margin-top: -54px; }

.mfp-arrow:hover, .mfp-arrow:focus {
  opacity: 1;
  filter: alpha(opacity=100); }

.mfp-arrow:before, .mfp-arrow:after, .mfp-arrow .mfp-b, .mfp-arrow .mfp-a {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent; }

.mfp-arrow:after, .mfp-arrow .mfp-a {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px; }

.mfp-arrow:before, .mfp-arrow .mfp-b {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7; }

.mfp-arrow-left {
  left: 0; }

.mfp-arrow-left:after, .mfp-arrow-left .mfp-a {
  border-right: 17px solid #FFF;
  margin-left: 31px; }

.mfp-arrow-left:before, .mfp-arrow-left .mfp-b {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F; }

.mfp-arrow-right {
  right: 0; }

.mfp-arrow-right:after, .mfp-arrow-right .mfp-a {
  border-left: 17px solid #FFF;
  margin-left: 39px; }

.mfp-arrow-right:before, .mfp-arrow-right .mfp-b {
  border-left: 27px solid #3F3F3F; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px; }

.mfp-iframe-holder .mfp-close {
  top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000; }

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }

.mfp-figure:after {
  content: '';
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444; }

.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px; }

.mfp-figure figure {
  margin: 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) {
  .breadcrumb {
    padding: 5px 0; }

  .logohide {
    display: none; }

  .reviewtextdesktop {
    display: none; } }
@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }

  .mfp-img-mobile img.mfp-img {
    padding: 0; }

  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }

  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }

  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }

  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0; }

  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }

  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }
@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }

  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }

  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }

  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }
.mfp-ie7 .mfp-img {
  padding: 0; }

.mfp-ie7 .mfp-bottom-bar {
  width: 600px;
  left: 50%;
  margin-left: -300px;
  margin-top: 5px;
  padding-bottom: 5px; }

.mfp-ie7 .mfp-container {
  padding: 0; }

.mfp-ie7 .mfp-content {
  padding-top: 44px; }

.mfp-ie7 .mfp-close {
  top: 0;
  right: 0;
  padding-top: 0; }

/*======================== Magnific Popup custom styles ===================== */
.mfp-bg {
  background-color: #eaeded; }

.mfp-close {
  color: inherit; }

.mfp-bg.mfp-fade {
  -webkit-backface-visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out; }

/* overlay animate in */
.mfp-bg.mfp-fade.mfp-ready {
  opacity: 1;
  filter: alpha(opacity=100); }

/* overlay animate out */
.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
  filter: alpha(opacity=100); }

/* content at start */
.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out; }

/* content animate it */
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1; }

/* content animate out */
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0; }

/*============================================================================
  #Product Reviews - Free Shopify App
    - https://apps.shopify.com/product-reviews
==============================================================================*/
#shopify-product-reviews {
  margin: 0;
  /*================ Summary Rating ================*/
  /*================ Content ================*/
  /*================ Individual Ratings ================*/
  /*================ Review Form ================*/ }
  #shopify-product-reviews .spr-container {
    padding: 0;
    border: 0 none; }
  #shopify-product-reviews .spr-summary {
    border-bottom: 1px solid #dedede;
    padding-bottom: 15px;
    margin-bottom: 30px; }
    @media screen and (min-width: 769px) {
      #shopify-product-reviews .spr-summary {
        float: left;
        border: 1px solid #dedede;
        width: 30%;
        padding: 10px; }
        #shopify-product-reviews .spr-summary .spr-summary-starrating {
          display: block;
          margin: 0; } }
  #shopify-product-reviews .spr-summary-caption {
    font-size: 14px; }
    @media screen and (min-width: 769px) {
      #shopify-product-reviews .spr-summary-caption {
        display: block;
        border-bottom: 1px solid #dedede;
        margin-bottom: 10px;
        padding-bottom: 10px; } }
  #shopify-product-reviews .spr-summary-actions-newreview:after {
    content: ' \203A';
    clear: none;
    display: inline; }
  @media screen and (min-width: 769px) {
    #shopify-product-reviews .spr-summary-actions-newreview {
      float: none; } }
  #shopify-product-reviews .spr-header:after {
    clear: none; }
  @media screen and (min-width: 769px) {
    #shopify-product-reviews .spr-content {
      float: left;
      width: 70%;
      padding-left: 30px; } }
  #shopify-product-reviews .spr-reviews {
    margin: 0; }
  #shopify-product-reviews .spr-review {
    padding: 0;
    margin: 0;
    border: 0 none; }
    #shopify-product-reviews .spr-review + .spr-review {
      margin-top: 15px; }
  #shopify-product-reviews .spr-review-content-body {
    font-size: inherit; }
    #shopify-product-reviews .spr-review-content-body br {
      content: '';
      display: block;
      overflow: hidden;
      height: 1px; }
  #shopify-product-reviews .spr-review-header {
    margin-bottom: 0; }
    #shopify-product-reviews .spr-review-header:after {
      content: "";
      display: table;
      clear: both; }
  #shopify-product-reviews .spr-review-header-title {
    margin-right: 15px;
    font-family: "Open Sans", "HelveticaNeue", "Helvetica Neue", sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 17px;
    line-height: 20px;
    position: relative;
    top: 2px; }
  @media screen and (min-width: 769px) {
    #shopify-product-reviews .spr-review-header-starratings,
    #shopify-product-reviews .spr-review-header-title {
      display: inline; }
    #shopify-product-reviews .spr-review-header-title {
      float: left; } }
  #shopify-product-reviews .spr-review-header-starratings {
    position: relative;
    top: -2px;
    margin: 0 15px 10px 0; }
  #shopify-product-reviews .spr-review-header-byline {
    position: relative;
    top: -2px;
    display: inline-block;
    margin: 0; }
  #shopify-product-reviews .spr-review-reportreview {
    display: none; }
  #shopify-product-reviews .spr-form {
    border: 0 none;
    border-bottom: 1px solid #dedede;
    margin: 0 0 30px;
    padding: 0 0 30px; }

/*================ Star Icons ================*/
.spr-badge-starrating {
  white-space: nowrap; }

.spr-badge-starrating,
.spr-icon {
  color: #ff9900;
  font-size: inherit; }

/*================ Review count ================*/
.spr-badge-caption {
  display: inline-block;
  font-size: 13px; }

/*================ Hide review badge if no reviews ================*/
.product-grid-item .spr-badge[data-rating="0.0"],
.product-meta--review .spr-badge[data-rating="0.0"] {
  display: none; }

/*============================================================================
  #Password Page
==============================================================================*/
.template-password {
  height: 100vh;
  text-align: center;
  padding: 0; }

.password-page__wrapper {
  display: table;
  height: 100%;
  width: 100%;
  background-image: url(//cdn.shopify.com/s/files/1/2383/2435/t/4/assets/password-page-background.jpg?v=12987344696580752000);
  background-size: cover;
  background-repeat: no-repeat;
  color: #ffffff; }
  .password-page__wrapper a {
    color: inherit; }
  .password-page__wrapper .social-sharing a {
    color: #ffffff; }

.password-page__header {
  display: table-row;
  height: 1px; }

.password-page__header__inner {
  display: table-cell;
  padding: 15px 30px;
  width: 100vw; }

.password-page__logo {
  margin-top: 90px; }
  .password-page__logo .logo {
    max-width: 100%; }

.password-page__main {
  display: table-row;
  width: 100%;
  height: 100%;
  margin: 0 auto; }

.password-page__main__inner {
  display: table-cell;
  vertical-align: middle;
  padding: 15px 30px;
  width: 100vw; }

.password-page__hero {
  font-family: "Open Sans", "HelveticaNeue", "Helvetica Neue", sans-serif;
  font-weight: 400;
  font-size: 3em;
  line-height: 1.25;
  text-transform: none;
  letter-spacing: 0; }
  @media screen and (min-width: 481px) {
    .password-page__hero {
      font-size: 4.28571em; } }
  @media screen and (min-width: 769px) {
    .password-page__hero {
      font-size: 4.57143em; } }

.password-page__message {
  font-style: italic;
  font-size: 120%; }
  .password-page__message img {
    max-width: 100%; }

.password-page__message,
.password-page__login-form,
.password-page__signup-form {
  max-width: 500px;
  margin: 0 auto; }

.password-page__login-form {
  text-align: center;
  padding: 30px; }

.password-page__signup-form .input-group-field {
  color: #ffffff; }

@media screen and (min-width: 480px) {
  .password-page__login-form,
  .password-page__signup-form {
    padding: 0 30px; } }
.password-page__login-form .input-group,
.password-page__signup-form .input-group {
  width: 100%; }
.password-page__login-form .errors ul,
.password-page__signup-form .errors ul {
  list-style-type: none;
  margin-left: 0; }

.storefront-password-form {
  margin-bottom: 15px; }

.password-page__social-sharing {
  margin-top: 30px; }
  .password-page__social-sharing .social-sharing {
    padding-top: 0; }

.password-login,
.admin-login {
  margin-top: 15px; }
  .password-login a:hover,
  .admin-login a:hover {
    color: inherit; }

.password-login {
  font-size: 1em;
  line-height: 14px; }

.lock-icon-svg {
  width: 14px;
  height: 14px;
  display: inline-block;
  vertical-align: baseline;
  /* Hiding the SVG logo in IE8 */ }
  .lt-ie9 .lock-icon-svg {
    display: none; }

.admin-login {
  font-size: 95%; }

.password-page__footer {
  display: table-row;
  height: 1px; }

.password-page__footer_inner {
  display: table-cell;
  vertical-align: bottom;
  padding: 30px;
  line-height: 21px;
  font-size: 95%; }

.shopify-link {
  color: inherit; }
  .shopify-link:hover {
    color: inherit; }

.shopify-logo-svg {
  width: 72px;
  height: 21px;
  display: inline-block;
  line-height: 0;
  vertical-align: top;
  /* Hiding the SVG logo in IE8, we show the word 'Shopify' instead */ }
  .lt-ie9 .shopify-logo-svg {
    display: none; }

/* =========
   Hiding the word 'Shopify' but not from screen readers.
   IE8 does not support SVG, so in it we hide the logo and show the word.
   To target all browsers except IE8, we use the class 'modern',
   which needs to be added to the html element.
   ========= */
.modern .shopify-name {
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
  position: absolute;
  height: 1px;
  width: 1px; }

/*============================================================================
  #Image with text
==============================================================================*/
.feature-row {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center; }
  @media screen and (max-width: 480px) {
    .feature-row {
      -webkit-flex-wrap: wrap;
      -moz-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap; } }

.feature-row__item {
  -webkit-flex: 0 1 50%;
  -moz-flex: 0 1 50%;
  -ms-flex: 0 1 50%;
  flex: 0 1 50%; }
  @media screen and (max-width: 480px) {
    .feature-row__item {
      -webkit-flex: 1 1 100%;
      -moz-flex: 1 1 100%;
      -ms-flex: 1 1 100%;
      flex: 1 1 100%;
      max-width: 100%; } }

.feature-row__image-wrapper {
  position: relative;
  margin: 0 auto; }

.feature-row__image {
  display: block;
  margin: 0 auto; }
  @media screen and (max-width: 480px) {
    .feature-row__image {
      order: 1; } }
  .js .feature-row__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; }

.feature-row__text {
  padding-top: 30px;
  padding-bottom: 30px; }
  @media screen and (max-width: 480px) {
    .feature-row__text {
      order: 2;
      padding-bottom: 0; } }

@media screen and (min-width: 481px) {
  .feature-row__text--left {
    padding-left: 30px; }

  .feature-row__text--right {
    padding-right: 30px; } }
@media screen and (min-width: 481px) {
  .featured-row__subtext {
    font-size: 1.14286em; } }
/*============================================================================
  #Custom Content
==============================================================================*/
.custom-content {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  -moz-align-items: stretch;
  -ms-align-items: stretch;
  -o-align-items: stretch;
  align-items: stretch;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: auto;
  margin-bottom: 0px;
  margin-left: -30px; }
  @media screen and (max-width: 480px) {
    .custom-content {
      margin-bottom: 0px;
      margin-left: -15px; } }

.custom__item {
  -webkit-flex: 0 0 auto;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin-bottom: 0px;
  padding-left: 30px;
  max-width: 100%; }
  @media screen and (max-width: 480px) {
    .custom__item {
      -webkit-flex: 0 0 auto;
      -moz-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      padding-left: 15px;
      margin-bottom: 0px; }
      .custom__item.small--one-half {
        -webkit-flex: 1 0 50%;
        -moz-flex: 1 0 50%;
        -ms-flex: 1 0 50%;
        flex: 1 0 50%;
        max-width: 400px;
        margin-left: auto;
        margin-right: auto; } }

.custom__item-inner {
  position: relative;
  display: inline-block;
  text-align: left;
  max-width: 100%; }

.custom__item-inner--video,
.custom__item-inner--html {
  display: block; }

/*================ Flex item alignment ================*/
.align--top-middle {
  text-align: center; }

.align--top-right {
  text-align: right; }

.align--middle-left {
  -ms-flex-item-align: center;
  -webkit-align-self: center;
  align-self: center; }

.align--center {
  -ms-flex-item-align: center;
  -webkit-align-self: center;
  align-self: center;
  text-align: center; }

.align--middle-right {
  -ms-flex-item-align: center;
  -webkit-align-self: center;
  align-self: center;
  text-align: right; }

.align--bottom-left {
  -ms-flex-item-align: flex-end;
  -webkit-align-self: flex-end;
  align-self: flex-end; }

.align--bottom-middle {
  -ms-flex-item-align: flex-end;
  -webkit-align-self: flex-end;
  align-self: flex-end;
  text-align: center; }

.align--bottom-right {
  -ms-flex-item-align: flex-end;
  -webkit-align-self: flex-end;
  align-self: flex-end;
  text-align: right; }

/*============================================================================
  #Blank States
==============================================================================*/
.placeholder-svg {
  display: block;
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2); }

.placeholder-noblocks {
  padding: 40px;
  text-align: center;
  max-width: 100%; }
  .placeholder-noblocks.slide-link {
    background-color: rgba(0, 0, 0, 0.1); }

.placeholder-background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.1); }
  .placeholder-background .icon {
    border: 0; }
  .placeholder-background.imagebox {
    background-color: #f3f3f3; }
    .placeholder-background.imagebox svg.placeholder-image {
      border: 0px;
      align-self: center;
      width: 100%;
      height: 100%; }

.image-bar__content .placeholder-svg {
  position: absolute;
  top: 0;
  left: 0; }

/*============================================================================
  #Lazysizes
==============================================================================*/
.lazyload__image-wrapper {
  display: block;
  margin-bottom: 10px;
  position: relative; }
  .lazyload__image-wrapper img {
    display: block; }
  .lazyload__image-wrapper img {
    display: block;
    width: 100%;
    margin: 0 auto;
    position: absolute;
    top: 0; }
    .lazyload__image-wrapper img.lazyload {
      opacity: 0; }

.box {
  background: no-repeat;
  background-color: #f7f7f7;
  background-size: contain; }

/*============================================================================
  #Featured blog post
==============================================================================*/
.article__featured-image {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%; }

.article__featured-image-wrapper {
  position: relative;
  margin-bottom: 30px; }
  .no-js .article__featured-image-wrapper {
    clip: rect(0 0 0 0);
    clip: rect(0, 0, 0, 0);
    overflow: hidden;
    position: absolute;
    height: 1px;
    width: 1px; }

.article__featured-image-wrapper-nojs {
  margin-bottom: 30px; }

.featured-blog__meta {
  margin-bottom: 0.5em; }

.featured-blog__post {
  margin-bottom: 37.5px; }

.placeholder .article__featured-link {
  margin-bottom: 30px; }

/*============================================================================
  #Map section
==============================================================================*/
.map-section__wrapper {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
  .ie9 .map-section__wrapper {
    display: block;
    position: relative; }

.map-section__content {
  -webkit-flex: 1 1 auto;
  -moz-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 290px;
  overflow: hidden;
  position: relative; }
  @media screen and (max-width: 768px) {
    .map-section__content {
      -webkit-flex: 0 1 100%;
      -moz-flex: 0 1 100%;
      -ms-flex: 0 1 100%;
      flex: 0 1 100%;
      max-width: 100%; } }
  @media screen and (min-width: 769px) {
    .ie9 .map-section__content {
      width: 50%; } }
  .map-section__content .icon-pin {
    font-size: 11.2px; }

.map-section__image {
  height: 100%;
  background-size: cover;
  background-position: center;
  padding-top: 50%; }
  @media screen and (max-width: 768px) {
    .map-section__image {
      order: 1; } }
  @media screen and (min-width: 769px) {
    .map-section__image {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0; } }
  .map-section--display-map .map-section__image {
    display: none !important; }

@media screen and (min-width: 769px) {
  .ie9 .map-section__background-wrapper {
    left: 50%;
    height: 100%;
    top: 0;
    position: absolute; } }

.map-section__placeholder {
  height: 100%; }
  @media screen and (min-width: 769px) {
    .map-section__placeholder {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0; } }
  .map-section--display-map .map-section__placeholder {
    display: none !important; }

.map-section__text {
  padding: 30px;
  background-color: #f8f8f8; }
  @media screen and (max-width: 768px) {
    .map-section__text {
      order: 0; } }

.map-section__container {
  width: 100%;
  height: 55vh; }
  @media screen and (min-width: 769px) {
    .map-section__container {
      position: absolute !important;
      top: 0;
      left: 0;
      height: 100%; } }

.footer-left {
  text-align: left; }

.smallarticlecontent {
  width: 80%; }

/*==============================================================================
  #Rich text section
==============================================================================*/
.rich-text__heading p {
  margin-bottom: 0; }

.rich-text__heading--large {
  font-size: 1.15em; }

.rich-text__heading--small {
  font-size: 0.88em; }

.rich-text__text--large {
  font-size: 1.28571em; }

.rich-text__text--small {
  font-size: 0.92857em; }

.show-nav ul#accessibleNav {
  display: block;
  position: static;
  background: #000000 !important; }

.nav-bar .wrapper {
  position: relative; }

ul#accessibleNav {
  display: block;
  position: static; }

.newsletter-form {
  background-color: #ffffff; }

.mobile-search-box {
  color: #ffffff; }

/* Style the tab */
.tab {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #f1f1f1; }

/* Style the buttons that are used to open the tab content */
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s; }

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd; }

/* Create an active/current tablink class */
.tab button.active {
  background-color: #ccc; }

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none; }
